import React, { useEffect, useState } from 'react';
import {
  Datagrid,
  TextField,
  TextInput,
  Filter,
  DateField,
  useQuery,
  Pagination,
  Loading,
  ListContextProvider,
  FunctionField,
  TopToolbar,
  CreateButton,
} from 'react-admin';
import { StepperFieldJobAr } from '../../components/StepperFieldJobAr';
import { Card, makeStyles } from '@material-ui/core';
import debounce from 'lodash/debounce';
import keyBy from 'lodash/keyBy';
import { buttonStyle } from '../../style';

const useStyles = makeStyles((theme) => ({
  label: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
  },
  paddingLeft: {
    paddingLeft: '14px',
  },
}));

const ArFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="batch_id,reels#house_isci@_ilike,pods#house_isci@_ilike" alwaysOn />
    </Filter>
  );
};

export const ArListActions = (props) => {
  const classes = buttonStyle();
  return (
    <TopToolbar style={{ marginLeft: 'auto' }}>
      <CreateButton className={classes.button} label="New Pod Job" basePath="pods" />
    </TopToolbar>
  );
};

const buildFilterQuery = (filterValues, page, perPage, currentSort) => {
  return {
    type: 'getList',
    resource: 'job',
    payload: {
      pagination: { page, perPage },
      sort: currentSort,
      filter: { ...filterValues, job_type: ['sareel', 'reel', 'localpod', 'nationalpod'] },
    },
  };
};

export const ArList = (props) => {
  const classes = useStyles();
  const [reelData, setReelData] = useState([]);
  const [reelIds, setReelIds] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [currentSort, setSort] = useState({ field: 'created', order: 'DESC' });
  const [filterValues, setFilterValues] = useState({ batch_id: '' });

  const { data, total } = useQuery(buildFilterQuery(filterValues, page, perPage, currentSort), {
    onFailure: (error) => {
      console.error(error);
    },
  });

  // useEffect(() => {
  //   if (eventSource) {
  //     eventSource.close()
  //   }
  //   const evtSource = new EventSource(`${window.REACT_APP_API_URL}/subscribe/reels?sort_order=${currentSort.order}&sort_field=${currentSort.field}&per_page=${perPage}&page=${page}`)
  //   setEventSource(evtSource)
  //   evtSource.addEventListener("new_event", function (event) {
  //     const newData = JSON.parse(event.data)
  //     setReelData(keyBy(newData, 'id'))
  //     setReelIds(newData.map(({id}) => id))
  //   });
  //   evtSource.addEventListener("end_event", function (event) {
  //     evtSource.close();
  //   });
  //
  //   return () => {
  //     evtSource.close();
  //   };
  // }, [currentSort, perPage,page]);

  useEffect(() => {
    if (data) {
      setReelData(keyBy(data, 'id'));
      setReelIds(data.map(({ id }) => id));
    }
  }, [data]);

  const debouncedHandleFilterChange = debounce((value) => {
    setFilterValues(value);
  }, 700);

  return reelData ? (
    <ListContextProvider
      value={{
        data: reelData,
        ids: reelIds,
        total,
        filterValues: filterValues,
        setFilters: debouncedHandleFilterChange,
        page,
        perPage,
        setPage,
        setPerPage,
        setSort: (field, order) => setSort({ field, order }),
        currentSort,
        resource: props.resource,
        selectedIds: [],
      }}>
      <div style={{ display: 'flex' }}>
        <ArFilter />
        <ArListActions />
      </div>
      <Card>
        <Datagrid
          rowClick={(id, basePath, record) => `${record.job_type.includes('reel') ? 'reel' : 'pod'}/${id}/show`}>
          <TextField source="batch_id" label="Reel ID" />
          <DateField source="created" label="Date Created" showTime />
          <FunctionField
            label="Job Type"
            style={{ textTransform: 'capitalize' }}
            render={(record) =>
              record.job_type === 'nationalpod'
                ? 'National POD'
                : record.job_type === 'localpod'
                  ? 'Local POD'
                  : record.job_type === 'sareel'
                    ? 'SA Reel'
                    : record.job_type
            }
          />
          <StepperFieldJobAr
            label={
              <div className={classes.label}>
                <span>Preflight</span>
                <span className={classes.paddingLeft}>Transcodes</span>
                <span className={classes.paddingLeft}>Transfers</span>
                <span>Notifications</span>
              </div>
            }></StepperFieldJobAr>
        </Datagrid>
        <Pagination rowsPerPageOptions={[25, 50, 100, 250, 500]} />
      </Card>
    </ListContextProvider>
  ) : (
    <Loading />
  );
};
