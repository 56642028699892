import React, { useEffect, useState } from 'react';
import {
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  ListContextProvider,
  Loading,
  Pagination,
  SelectInput,
  TextField,
  TextInput,
  useQuery,
} from 'react-admin';
import { ProgressField } from '../../components/ProgressBar';
import { StatusField } from '../../components/StatusField';
import keyBy from 'lodash/keyBy';
import debounce from 'lodash/debounce';
import { Card } from '@material-ui/core';

const JobFilter = (props) => {
  return (
    <Filter>
      <TextInput
        label="Search"
        source="batch_id@_ilike,brs_job#request_id@_ilike,brs_job#broadcastrequests#isci@_ilike,reels#house_isci@_ilike,pods#house_isci@_ilike"
        alwaysOn
      />
      <SelectInput
        source="job_type"
        choices={[
          { id: 'batch', name: 'Batch' },
          { id: 'reel', name: 'Reel' },
          { id: 'sareel', name: 'SA Reel' },
          { id: 'brs', name: 'BRS' },
          { id: 'nationalpod', name: 'National POD' },
          { id: 'localpod', name: 'Local POD' },
        ]}
        alwaysOn
      />
    </Filter>
  );
};

const buildFilterQuery = (filterValues, page, perPage, currentSort) => {
  return {
    type: 'getList',
    resource: 'job',
    payload: {
      pagination: { page, perPage },
      sort: currentSort,
      filter: { ...filterValues },
    },
  };
};

export const JobList = (props) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [currentSort, setSort] = useState({ field: 'updated', order: 'DESC' });
  const [filterValues, setFilterValues] = useState({});
  const [jobIds, setJobIds] = useState([]);
  const [jobData, setJobData] = useState([]);
  const { data, total, loading } = useQuery(buildFilterQuery(filterValues, page, perPage, currentSort), {
    onFailure: (error) => {
      console.error(error);
    },
  });

  useEffect(() => {
    if (data) {
      setJobData(keyBy(data, 'id'));
      setJobIds(data.map(({ id }) => id));
    }
  }, [data]);

  const debouncedHandleFilterChange = debounce((value) => {
    setFilterValues(value);
  }, 700);

  const handleRowClick = (id, basePath, e) => {
    const clickUrl = `${basePath}/${id}/show`;
    const ctrlClickUrl = `#${basePath}/${id}/show`;
    // Prevent the default behavior of the click
    e.preventDefault();

    if (e.ctrlKey || e.metaKey) {
      // Open in new tab
      window.open(ctrlClickUrl, '_blank');
    } else {
      // Default behavior
      props.history.push(clickUrl);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <ListContextProvider
      value={{
        data: jobData,
        ids: jobIds,
        total,
        filterValues: filterValues,
        setFilters: debouncedHandleFilterChange,
        page,
        perPage,
        setPage,
        setPerPage,
        setSort: (field, order) => setSort({ field, order }),
        currentSort,
        resource: props.resource,
        selectedIds: [],
      }}>
      <JobFilter />
      <Card>
        <Datagrid
        //we had to remove react-admin rowClick to add ctrl + click for opening
        //in new tab functionality because rowClick prop in Datagrid does not
        //support event handlers like javascript onClick does
        //rowClick="show"
        >
          <FunctionField
            label="ID"
            render={(record) => (
              <TextField
                onClick={(e) => handleRowClick(record.id, props.basePath, e)}
                style={{ cursor: 'pointer' }}
                record={record}
                source={record.job_type === 'brs' ? 'brs_job.request_id' : 'batch_id'}
              />
            )}
          />
          <FunctionField
            label="Job Type"
            style={{ textTransform: 'capitalize' }}
            render={(record) => {
              const jobTypeLabel =
                record.job_type === 'brs'
                  ? 'BRS'
                  : record.job_type === 'nationalpod'
                    ? 'National POD'
                    : record.job_type === 'localpod'
                      ? 'Local POD'
                      : record.job_type === 'sareel'
                        ? 'SA Reel'
                        : record.job_type; // Fallback to the job_type value if none matches

              return (
                <span onClick={(e) => handleRowClick(record.id, props.basePath, e)} style={{ cursor: 'pointer' }}>
                  {jobTypeLabel}
                </span>
              );
            }}
          />
          <DateField source="created" label="Date Created" showTime />
          <DateField source="updated" label="Date Updated" showTime />
          <StatusField label="Status" source="status"></StatusField>
          <ProgressField source="progress" type="job" />
        </Datagrid>
        <Pagination rowsPerPageOptions={[25, 50, 100, 250, 500]} />
      </Card>
    </ListContextProvider>
  );
};
