import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  label: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
  },
  paddingLeft: {
    paddingLeft: '14px',
  },
  button: {
    backgroundColor: '#3CE7EA',
    color: '#2A2A2A',
    borderRadius: 'unset',
    padding: '4px 8px 4px 8px',
    textTransform: 'none',
    fontSize: '14px',
    opacity: 0.9,
    '&:hover': {
      backgroundColor: '#3CE7EA',
      color: '#2A2A2A',
      opacity: 1,
      boxShadow: 'none',
    },
    '&.Mui-disabled': {
      color: '#484646',
      backgroundColor: '#3c9191',
      pointerEvents: 'auto',
      cursor: 'not-allowed',
    },
  },
  jobButtons: {
    backgroundColor: '#3CE7EA',
    color: '#2A2A2A',
    borderRadius: 'unset',
    padding: '4px 8px 4px 8px',
    textTransform: 'none',
    fontSize: '14px',
    opacity: 0.9,
    '&:hover': {
      backgroundColor: '#3CE7EA',
      color: '#2A2A2A',
      opacity: 1,
      boxShadow: 'none',
    },
  },
  groupButtons: {
    '& > *:not(:last-child)': {
      borderRight: '1px solid black',
    },
  },
  arrowDownButton: {
    backgroundColor: '#3CE7EA',
    color: '#2A2A2A',
    borderRadius: 'unset',
    borderLeft: '1px solid black',
    padding: '4px 15px 9px 0px',
    textTransform: 'none',
    fontSize: '14px',
    opacity: 0.9,
    '&:hover': {
      backgroundColor: '#3CE7EA',
      color: '#2A2A2A',
      opacity: 1,
      boxShadow: 'none',
    },
    '&.Mui-disabled': {
      color: '#484646',
      backgroundColor: '#3c9191',
      pointerEvents: 'auto',
      cursor: 'not-allowed',
    },
  },
  newStrataMenu: {},
  dialogPaper: {},
  dialogTitle: {
    padding: '16px 14px 10px 14px',
  },
  dialogContent: {
    padding: '0 5px 5px 5px',
  },
  dialogActions: {
    padding: '0 5px 5px 5px',
  },
}));
