import * as React from 'react';
import { useState, useEffect } from 'react';
import { useEditController, EditContextProvider, SimpleForm, useDataProvider, useNotify, Button } from 'react-admin';
import { IconButton, Typography, Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import { EditPathMappingsStyle } from '../../style';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import { Toolbar, SaveButton, DeleteButton } from 'react-admin';

const CustomToolbar = ({
  hasShow,
  basePath,
  hasCreate,
  hasEdit,
  hasList,
  isEdit = false,
  recipients,
  destination_id,
  destinationRecipients,
  ...props
}) => {
  const classes = EditPathMappingsStyle();
  const [isLoading, setIsLoading] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const rIds = recipients.map((r) => r.id);
  const deleteRecipients = destinationRecipients && destinationRecipients.filter((r) => !rIds.includes(r.id));
  const createdRecipients = recipients.filter((r) => !r.destination_recipient_id);
  const hasNewRecipients =
    (deleteRecipients && deleteRecipients.length > 0) || (createdRecipients && createdRecipients.length > 0);

  const handleSave = async () => {
    const deleteIds = deleteRecipients.map((r) => r.destination_recipient_id);
    setIsLoading(true);
    if (recipients.length >= 1) {
      await Promise.all(
        recipients.map(async (recipient) => {
          if (recipient.destination_recipient_id) return;
          await dataProvider.create('destinationrecipient', {
            data: {
              destination_id,
              recipient_id: recipient.id,
            },
          });
        }),
      )
        .then(() => setIsLoading(false))
        .catch((err) => console.log(err));
    }
    if (deleteIds && deleteIds.length >= 1) {
      await dataProvider
        .deleteMany('destinationrecipient', {
          ids: deleteIds,
        })
        .then(({ data }) => {})
        .catch((error) => {
          notify(`${error}`, 'warning');
        });
    }
    props.onCancel();
  };

  return (
    <Toolbar style={{ backgroundColor: 'transparent' }} {...props}>
      <SaveButton disabled={!hasNewRecipients || isLoading} onClick={() => handleSave()} className={classes.button} />
      <Button className={classes.outlinedButton} icon={<ClearIcon />} label="Cancel" onClick={() => props.onCancel()} />
      {isEdit && <DeleteButton undoable={true} style={{ marginLeft: 'auto' }} />}
    </Toolbar>
  );
};

const EditRecipient = ({ onCancel, ...props }) => {
  const classes = EditPathMappingsStyle();
  const controllerProps = useEditController(props);
  const [recipients, setRecipients] = useState([]);
  const [allRecipients, setAllRecipients] = useState([]);
  const [addNewRecipients, setAddNewRecipients] = useState([]);
  useEffect(() => {
    if (props.recipients) {
      setRecipients(props.recipients);
      setAllRecipients(props.allRecipients);
      setAddNewRecipients([]);
    }
  }, [props.recipients, props.allRecipients]);

  const deleteRecipient = (index, id) => {
    let newRecipients = [...recipients];
    newRecipients.splice(index, 1);
    setRecipients([...newRecipients]);
    setAllRecipients([...allRecipients, recipients[index]]);
  };

  const handleAddNewRecipients = () => {
    let newRecipients;
    newRecipients = recipients.concat(addNewRecipients);
    setRecipients(newRecipients);
    const rIds = newRecipients.map((r) => r.id);
    const newAllRecipients = allRecipients.filter((r) => !rIds.includes(r.id));
    setAllRecipients(newAllRecipients);
    setAddNewRecipients([]);
  };

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography style={{ marginTop: '18px', marginLeft: '14px' }} variant="h6">
          Recipients
        </Typography>
      </div>
      <EditContextProvider value={controllerProps}>
        <SimpleForm
          className={classes.form}
          basePath={controllerProps.basePath}
          record={controllerProps.record}
          save={controllerProps.save}
          version={controllerProps.version}
          redirect="list"
          resource="reviews"
          toolbar={
            <CustomToolbar
              {...props}
              onCancel={() => onCancel()}
              destination_id={props.id}
              destinationRecipients={props.recipients}
              recipients={recipients}
            />
          }>
          <FormControl className={classes.formControl}>
            <InputLabel id="Recipient">Recipient</InputLabel>
            <Select
              label="Recipient"
              id="allRecipients"
              multiple
              value={addNewRecipients}
              onChange={(e) => {
                setAddNewRecipients(e.target.value);
              }}
              style={{ width: '75%' }}>
              {allRecipients &&
                allRecipients.map(
                  (recipient) =>
                    recipient && (
                      <MenuItem key={recipient.id} value={recipient}>
                        {recipient.email}
                      </MenuItem>
                    ),
                )}
            </Select>
            <div>
              <Button
                label="add"
                className={classes.outlinedButton}
                disabled={addNewRecipients.length === 0}
                style={{ height: '20%', display: 'block' }}
                onClick={handleAddNewRecipients}
              />
            </div>
          </FormControl>
          {recipients &&
            recipients.map((r, index) => {
              return (
                <div key={index} className={classes.inputWithDeleteIcon}>
                  <TextField
                    style={{
                      width: '100%',
                      marginTop: '16px',
                      marginLeft: '10px',
                    }}
                    name="email"
                    label="Email"
                    value={r.recipient ? r.recipient.email : r.email}></TextField>
                  <IconButton
                    className={classes.deleteButton}
                    onClick={() => deleteRecipient(index, r.recipient_id ? r.recipient_id : r.id)}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              );
            })}
        </SimpleForm>
      </EditContextProvider>
    </div>
  );
};

export default EditRecipient;
