import * as React from 'react';
import { Layout, Sidebar, usePermissions } from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';
import { darkTheme } from './theme';

const CustomSidebar = (props) => <Sidebar {...props} size={200} />;

export const LayoutComponent = (props) => {
  const { loaded } = usePermissions();
  if (!loaded) {
    // Show a loading indicator while permissions are being fetched
    return <div>Loading...</div>;
  }

  return <Layout {...props} appBar={AppBar} sidebar={CustomSidebar} menu={Menu} theme={darkTheme} />;
};
