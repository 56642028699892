import { Datagrid, List, TextField, ReferenceField } from 'react-admin';
import { CustomPagination } from '../../components/CustomPagination';
import { FilterList, ListActions } from '../../components/index';

export const DestinationList = (props) => {
  return (
    <List
      {...props}
      actions={<ListActions />}
      perPage={100}
      pagination={<CustomPagination rowsPerPageOptions={[25, 50, 100, 250, 500]} />}
      filters={<FilterList />}>
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <ReferenceField source="location_id" reference="location" label="Location Name">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="id" />
        <TextField source="call_letters" label="Destination ID" />
        <ReferenceField source="format_id" reference="format">
          <TextField source="ext" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};
