import { jwtDecode } from 'jwt-decode';

import { LOGIN_API_PATH } from '../constants';

export const getRolesFromAuth = (user) => {
  if (!user) {
    return null;
  }
  return user.user_data.Auth.find((auth) => auth.ApplicationId === process.env.REACT_APP_APPLICATION_ID).Roles;
};
export const getRole = (roles) => {
  if (!roles) {
    return null;
  } else if (roles.includes('aviary-admin')) {
    return 'aviary-admin';
  } else if (roles.includes('dh-admin')) {
    return 'dh-admin';
  } else if (roles.includes('dh-user')) {
    return 'dh-user';
  } else if (roles.includes('ar-user') && !roles.includes('brs-user')) {
    return 'ar-user';
  } else if (!roles.includes('ar-user') && roles.includes('brs-user')) {
    return 'brs-user';
  } else if (roles.includes('ar-user') && roles.includes('brs-user')) {
    return 'ar-brs-user';
  } else {
    return 'no-role';
  }
};

export const authProvider = {
  login: ({ username, password }) => {
    const loginApiUrl = `${window.REACT_APP_API_URL}${LOGIN_API_PATH}`;
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);

    const request = new Request(loginApiUrl, {
      method: 'POST',
      body: formData,
    });

    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          console.error('Error in response:', response);
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        // Extract the access_token from the response
        const token = data.access_token;
        const userRoles = data.roles;
        if (!token) {
          throw new Error('Access token is undefined or missing');
        }

        const decodedToken = jwtDecode(token);

        const expirationDate = new Date(decodedToken.exp * 1000);

        localStorage.setItem('token', token);
        localStorage.setItem('token_expiration', expirationDate);
        localStorage.setItem('userRoles', userRoles);
        localStorage.setItem('userData', data);
        return Promise.resolve();
      })

      .catch((error) => {
        console.error('Error during login:', error);
      });
  },

  // called when the user navigates to a new location, to check for authentication
  // checkAuth: () => {
  //   if (window.CADENT_SSO_V2 === "true") {
  //     let token = Auth.getToken();
  //     let roles = getRolesFromAuth(Auth.userData);
  //     return token &&
  //       roles &&
  //       (roles.includes("ar-user") ||
  //         (roles && roles.includes("aviary-admin")) ||
  //         (roles && roles.includes("dh-admin")) ||
  //         (roles && roles.includes("dh-user")))
  //       ? Promise.resolve()
  //       : Promise.reject();
  //   }
  //   if (localStorage.getItem("app")) {
  //     const app = JSON.parse(
  //       CryptoJS.AES.decrypt(
  //         localStorage.getItem("app"),
  //         process.env.REACT_APP_APPLICATION_ID,
  //       ).toString(CryptoJS.enc.Utf8),
  //     );
  //     const expires = moment.utc(app.expires).valueOf();
  //     return (app.ssoComplete &&
  //       expires > moment.utc(Date.now()).valueOf() &&
  //       app.roles &&
  //       app.roles.includes("ar-user")) ||
  //       (app.roles && app.roles.includes("aviary-admin")) ||
  //       (app.roles && app.roles.includes("dh-admin")) ||
  //       (app.roles && app.roles.includes("dh-user")) ||
  //       (app.roles && app.roles.includes("brs-user"))
  //       ? Promise.resolve()
  //       : Promise.reject();
  //   }
  //   return Promise.reject();
  // },

  checkAuth: () => {
    const token = localStorage.getItem('token');
    //const roles = localStorage.getItem("roles");
    const tokenExpiration = localStorage.getItem('token_expiration');
    if (token && new Date(tokenExpiration) > new Date()) {
      return Promise.resolve();
    } else {
      return Promise.reject();
    }
  },

  // called when the user clicks on the logout button
  // logout: () => {
  //   if (window.CADENT_SSO_V2 === "true") {
  //     Auth.logout();
  //     return Promise.resolve();
  //   }
  //   localStorage.removeItem("app");
  //   localStorage.removeItem("roles");
  //   return Promise.resolve();
  // },

  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('token_expiration');
    localStorage.removeItem('userRoles');
    localStorage.removeItem('userData');
    localStorage.removeItem('estimateData');
    return Promise.resolve();
  },

  // called when the API returns an error
  // checkError: ({ status }) => {
  //   if (status === 401 || status === 403) {
  //     if (window.CADENT_SSO_V2 === "true") {
  //       Auth.logout();
  //       return Promise.reject();
  //     }
  //     localStorage.removeItem("app");
  //     localStorage.removeItem("roles");
  //     return Promise.reject();
  //   }
  //   return Promise.resolve();
  // },

  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      localStorage.removeItem('token_expiration');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getIdentity: () => {
    try {
      const { id, fullName, avatar } = jwtDecode(localStorage.getItem('token'));
      return Promise.resolve({ id, fullName, avatar });
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const token = localStorage.getItem('token');
    const userData = localStorage.getItem('userData');
    if (token) {
      return Promise.resolve(userData);
    }
    return Promise.reject();
  },
};
