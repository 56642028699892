import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useInput } from 'ra-core';
import { useForm } from 'react-final-form';
import { Card, makeStyles, Typography, Button } from '@material-ui/core';
import { AutocompleteInput, ChipField, useGetList, useNotify, useDataProvider, TextInput } from 'react-admin';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  listContainer: {
    width: '50%',
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-start',
  },
  list: {
    height: theme.spacing(50),
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
  },
  listHeader: {
    display: 'block',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
  },
  textField: {
    width: '100%',
    marginTop: 0,
  },
  textAreaField: {
    width: '100%',
    height: '100%',
    marginTop: 0,
  },
  hiddenTextField: {
    '& .MuiFilledInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiFormHelperText-contained': {
      display: 'none',
    },
    display: 'none',
    visibility: 'hidden',
  },
  autocomplete: {
    width: '100%',
  },
  button: {
    margin: theme.spacing(1),
    width: '90%',
  },
  textarea: {
    height: '100%',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2.5),
  },
  textareaActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
}));

export const StrataDestinationListInput = ({
  resource,
  source,
  sort,
  label,
  style,
  showDestinationTextArea,
  estimateData,
  onValidUUIDs,
}) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { data, loading } = useGetList(resource, { page: 1, perPage: 10000 }, sort);
  const form = useForm();
  const { input, meta } = useInput({ source });
  const [selectedChoices, setSelectedChoices] = useState(estimateData.selectedDestinations || input.value || []);
  const [textareaValue, setTextareaValue] = useState('');

  // Helper function to find matching destination with original case
  const findOriginalDestination = (inputDestination) => {
    const matchingItem = Object.values(data).find(
      item => item.call_letters.toLowerCase() === inputDestination.toLowerCase()
    );
    return matchingItem ? matchingItem.call_letters : inputDestination;
  };

  // Initialize with estimateData.selectedDestinations
  useEffect(() => {
    if (estimateData?.selectedDestinations) {
      setSelectedChoices(estimateData.selectedDestinations);
      form.change(source, estimateData.selectedDestinations);
    }
  }, [estimateData]);

  // Validate preloaded chips against data
  useEffect(() => {
    // Only validate when data is loaded and not in loading state
    if (!loading && data && selectedChoices.length > 0) {
      const validDestinations = [];
      const invalidDestinations = [];

      selectedChoices.forEach(choice => {
        if (Object.values(data).some(item => item.call_letters.toLowerCase() === choice.toLowerCase())) {
          // Use the original case from data
          validDestinations.push(findOriginalDestination(choice));
        } else {
          invalidDestinations.push(choice);
        }
      });

      // Update chips to only show valid destinations
      setSelectedChoices(validDestinations);
      form.change(source, validDestinations);

      // Get UUIDs for initial valid destinations and pass to parent
      if (estimateData.selectedDestinations && estimateData.selectedUUIDs && onValidUUIDs) {
        const validUUIDs = validDestinations
          .map(destination => estimateData.selectedUUIDs[destination])
          .filter(uuid => uuid);
        onValidUUIDs(validUUIDs);
      }

      // Move invalid destinations to textarea
      if (invalidDestinations.length > 0) {
        setTextareaValue(invalidDestinations.join(', '));
      }
    }
  }, [data, loading]);

  const availableChoices = useMemo(() => {
    if (!data) return [];
    const allChoices = Object.values(data).map((item) => item.call_letters);
    return allChoices.filter((choice) => !selectedChoices.includes(choice));
  }, [data, selectedChoices]);

  const handleAdd = (selectedChoice) => {
    if (!selectedChoice || selectedChoices.includes(selectedChoice)) return;

    // Use the original case from data if it's a valid destination
    const originalCaseChoice = findOriginalDestination(selectedChoice);
    const updatedChoices = [...selectedChoices, originalCaseChoice];
    setSelectedChoices(updatedChoices);
    form.change(source, updatedChoices);

    // Reset the input value
    form.change('destination_id_autocomplete', '');
  };

  const handleRemove = (choiceToRemove) => {
    const updatedChoices = selectedChoices.filter((choice) => choice !== choiceToRemove);
    setSelectedChoices(updatedChoices);
    form.change(source, updatedChoices);
  };

  const handleAddFromTextarea = () => {
    const newDestinations = textareaValue.split(',').map(item => item.trim());
    const validDestinations = [];
    const invalidDestinations = [];

    // Validate each destination
    newDestinations.forEach(destination => {
      if (Object.values(data).some(item => item.call_letters.toLowerCase() === destination.toLowerCase())) {
        // Use the original case from data
        validDestinations.push(findOriginalDestination(destination));
      } else {
        invalidDestinations.push(destination);
      }
    });

    // Add only valid destinations to chips
    if (validDestinations.length > 0) {
      const updatedChoices = [...new Set([...selectedChoices, ...validDestinations])];
      setSelectedChoices(updatedChoices);
      form.change(source, updatedChoices);
    }

    // Notify if there are invalid destinations
    if (invalidDestinations.length > 0) {
      notify(`The following destinations are not valid: ${invalidDestinations.join(', ')}`, 'warning');
    }

    // Keep invalid destinations in textarea
    setTextareaValue(invalidDestinations.join(', '));
  };

  const handleTextareaRemove = () => {
    const toRemove = textareaValue.split(',').map((item) => item.trim());
    const updatedChoices = selectedChoices.filter((choice) => !toRemove.includes(choice));
    setSelectedChoices(updatedChoices);
    form.change(source, updatedChoices);
    setTextareaValue('');
  };

  return (
    <div className={classes.container} style={style}>
      <Typography variant="subtitle1">{label}</Typography>
      <Card className={classes.root} variant="outlined">
        <div className={classes.listContainer}>
          <div className={classes.listHeader}>
            <AutocompleteInput
              className={classes.autocomplete}
              choices={availableChoices.map((call_letters) => ({
                id: call_letters,
                name: call_letters,
              }))}
              source="destination_id_autocomplete"
              helperText={false}
              allowEmpty={true}
              label="Search"
              loading={loading}
              input={{
                ...input,
                value: '', // Keep input field clear after selection
                onChange: (selectedChoice) => {
                  if (!selectedChoice || selectedChoices.includes(selectedChoice)) return;
                  handleAdd(selectedChoice);
                  // Force blur on the autocomplete input
                  document.activeElement.blur();
                },
              }}
              meta={meta}
              translateChoice={false}
              resettable
              optionText="name"
            />
          </div>
          <div className={classes.list}>
            {selectedChoices.map((choice) => (
              <ChipField
                key={choice}
                record={{ call_letters: choice }}
                source="call_letters"
                onDelete={() => handleRemove(choice)}
              />
            ))}
          </div>
        </div>
        {showDestinationTextArea && (
          <div className={classes.listContainer}>
            <div className={classes.textarea}>
              <TextInput
                multiline
                source="destination_id_textarea"
                label="Destination IDs"
                minRows={23}
                className={classes.textAreaField}
                placeholder="Enter multiple comma separated destinations ids"
                onChange={(event) => setTextareaValue(event.target.value)}
                inputProps={{
                  value: textareaValue,
                }}
                helperText={false}
              />
            </div>
            <div className={classes.textareaActions}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleAddFromTextarea}
                disabled={textareaValue === ''}
                style={{ width: '30%' }}>
                Add
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleTextareaRemove}
                disabled={textareaValue === ''}
                style={{ width: '30%' }}>
                Remove
              </Button>
            </div>
          </div>
        )}
      </Card>
    </div>
  );
};

StrataDestinationListInput.propTypes = {
  resource: PropTypes.string.isRequired,
  source: PropTypes.string,
  sort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  style: PropTypes.object,
  className: PropTypes.string,
  loading: PropTypes.bool,
  showDestinationTextArea: PropTypes.bool,
  canDelete: PropTypes.bool,
  onValidUUIDs: PropTypes.func,
};

StrataDestinationListInput.defaultProps = {
  style: {},
  choices: [],
  label: 'Destinations',
  showDestinationTextArea: true,
  canDelete: true,
};
