import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import CloseIcon from '@material-ui/icons/RemoveCircleOutline';
import {
  ArrayInput,
  BooleanInput,
  Button,
  Create,
  email,
  ReferenceInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  required,
  AutocompleteInput,
} from 'react-admin';
import { CustomToolbar } from '../../components/CustomToolBar';

import { createUpdateStyle } from '../../style';

export const RecipientCreate = (props) => {
  const classes = createUpdateStyle();

  const validateEmail = email();
  return (
    <div className={classes.card}>
      <a className={classes.breadcrumbLink} href="#/recipient">
        {'< Recipients'}
      </a>
      <Create title="Add Recipient" {...props}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar {...props} />}>
          <Typography variant="h6">Add Recipient</Typography>
          <TextInput className={classes.textField} helperText={false} source="first_name" validate={required()} />
          <TextInput className={classes.textField} helperText={false} source="last_name" validate={required()} />
          <TextInput
            type="email"
            className={classes.textField}
            helperText={false}
            source="email"
            validate={[required(), validateEmail]}
          />
          <ArrayInput source="destinations">
            <SimpleFormIterator
              disableReordering
              addButton={
                <Button label="ra.action.add" style={{ color: '#3CE7EA' }}>
                  <AddIcon />
                </Button>
              }
              removeButton={
                <Button label="ra.action.remove" style={{ color: '#e57373' }}>
                  <CloseIcon />
                </Button>
              }>
              <ReferenceInput
                className={classes.textField}
                helperText={false}
                source="destination_id"
                reference="destination"
                sort={{ field: 'name', order: 'ASC' }}
                label="Destination"
                filterToQuery={(searchText) => ({ name: searchText })}
                perPage={10000}
                validate={required()}>
                <AutocompleteInput optionText="name" />
              </ReferenceInput>
              <BooleanInput
                className={classes.switch}
                source="traffic_instructions"
                helperText={false}
                label="Traffic Instructions"
              />
              <BooleanInput className={classes.switch} source="media_delivery" helperText={false} label="Media" />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </Create>
    </div>
  );
};
