import React, { useState } from 'react';
import { Datagrid, DateField, Filter, List, Pagination, TextField, TextInput, TopToolbar } from 'react-admin';
import {
  Button,
  MenuItem,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuList,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { StepperFieldJobBrs } from '../../components';
import { useHistory } from 'react-router-dom';
import styles from 'src/pages/brs/BrsListStyles';
import { BrsCreateStrataModal } from 'src/pages/brs/BrsCreateStrataModal';

const BrsFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="request_id,broadcastrequests#isci@_ilike" alwaysOn />
    </Filter>
  );
};

export const BrsListActions = (props) => {
  const classes = styles();
  const history = useHistory();
  const options = ['New BRS Job', 'New Strata Job'];
  const [open, setOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleClick = () => {
    if (selectedIndex === 0) {
      history.push(`/brs/create`);
    } else if (selectedIndex === 1) {
      setModalOpen(true);
    }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };
  return (
    <TopToolbar style={{ marginLeft: 'auto' }}>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
        className={classes.groupButtons}>
        <Button onClick={handleClick} className={classes.jobButtons}>
          {options[selectedIndex]}
        </Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          className={classes.jobButtons}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        style={{ zIndex: 1300 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      //disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}>
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Dialog
        style={{ zIndex: 1 }}
        open={modalOpen}
        onClose={handleModalClose}
        onEscapeKeyDown={handleModalClose}
        classes={{ paper: classes.dialogPaper }}>
        <DialogTitle className={classes.dialogTitle}>New Strata Job</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <BrsCreateStrataModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
        </DialogContent>
        <DialogActions className={classes.dialogActions}></DialogActions>
      </Dialog>
    </TopToolbar>
  );
};

export const BrsList = (props) => {
  const classes = styles();
  return (
    <List
      perPage={500}
      pagination={<Pagination rowsPerPageOptions={[1, 25, 50, 100, 250, 500]} />}
      actions={<BrsListActions />}
      bulkActionButtons={false}
      title="BRS Dashboard"
      filters={<BrsFilter />}
      resource="brsjob"
      basePath={'/brs'}
      sort={{ field: 'created', order: 'DESC' }}
      hasCreate
      hasEdit
      hasShow
      hasList>
      <Datagrid rowClick="show">
        <TextField source="request_id" label="ID" />
        <DateField source="created" label="Date Created" showTime />
        <TextField source="estimate_id" />
        <StepperFieldJobBrs
          label={
            <div className={classes.label}>
              <span>Preflight</span>
              <span className={classes.paddingLeft}>Media</span>
              <span>Notifications</span>
            </div>
          }></StepperFieldJobBrs>
      </Datagrid>
    </List>
  );
};
