import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { TextInput, useNotify } from 'react-admin';
import { useForm } from 'react-final-form';
import difference from 'lodash/difference';
import { Button } from '@material-ui/core';
//import { __InputValue } from 'graphql';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  listContainer: {
    padding: theme.spacing(1),
  },
  list: {
    height: theme.spacing(50),
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
  },
  listHeader: {
    display: 'block',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
  },
  textField: {
    width: '100%',
    marginTop: 0,
  },
  textAreaField: {
    width: '100%',
    height: '100%',
    marginTop: 0,
  },
  hiddenTextField: {
    '& .MuiFilledInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiFormHelperText-contained': {
      display: 'none',
    },
    display: 'none',
    visibility: 'hidden',
  },
  autocomplete: {
    width: '100%',
  },
  textarea: {
    height: '100%',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2.5),
  },
  textareaActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
  outlinedButton: {
    marginTop: 12,
    backgroundColor: 'transparent',
    marginLeft: '16px',
    border: '1px solid #3CE7EA',
    color: '#3CE7EA',
    borderRadius: 'unset',
    padding: '4px 8px 4px 8px',
    opacity: 0.9,
    '&:hover': {
      backgroundColor: '#3CE7EA',
      color: '#2A2A2A',
      opacity: 1,
      boxShadow: 'none',
    },
    '&:disabled,button[disabled]': {
      borderColor: '#ffffff1f',
    },
    '& svg': {
      marginRight: '6px',
    },
  },
  button: {
    backgroundColor: '#3CE7EA',
    color: '#2A2A2A',
    borderRadius: 'unset',
    padding: '4px 8px 4px 8px',
    opacity: 0.9,
    '&:hover': {
      backgroundColor: '#3CE7EA',
      color: '#2A2A2A',
      opacity: 1,
      boxShadow: 'none',
    },
    '& svg': {
      marginRight: '6px',
      fontSize: '18px',
    },
  },
}));

export const BroadcastListInput = (props) => {
  const { broadcastRequests } = props;
  const form = useForm();
  const classes = useStyles();
  const notify = useNotify();
  const [textareaValue, setTextareaValue] = useState('');
  const handleAddFromTextarea = () => {
    let values = form.getState().values;
    const { brs_spots = [] } = values; // Ensure brs_spots is an array
    const iscis_added = brs_spots?.map((br) => br?.isciasset?.isci);
    const iscis_input = textareaValue
      .split(/,|\n/)
      .map((iscis) => iscis.trim())
      .filter(Boolean);
    const iscis = difference(iscis_input, iscis_added);
    let spots = broadcastRequests?.filter((br) => iscis.includes(br.isciasset.isci));
    let valid_iscis = spots.map((br) => br.isciasset.isci);
    let removed_iscis = difference(iscis_input, iscis);
    const invalid_iscis = difference(iscis, removed_iscis, valid_iscis);
    if (removed_iscis.length > 0) {
      notify(
        `Some of the ISCI(s) you entered ${removed_iscis.length < 2 ? 'is' : 'are'} already in the list: ${removed_iscis}`,
        'warning',
        { autoHideDuration: 5000, multiLine: true },
      );
    }
    if (invalid_iscis.length > 0) {
      notify(
        `Some of the ISCI(s) you entered ${invalid_iscis.length < 2 ? 'is' : 'are'} not valid: ${invalid_iscis}`,
        'warning',
        {
          autoHideDuration: 5000,
          multiLine: true,
        },
      );
    }
    const new_spots = [...brs_spots, ...spots];
    form?.change('brs_spots', new_spots);
    props?.handleDialog(false);
  };

  return (
    <div>
      <div>
        <TextInput
          multiline
          source="input_spot_list"
          label="ISCIs"
          minRows={23}
          className={classes.textAreaField}
          placeholder="Enter multiple comma separated ISCIS"
          onChange={(event) => setTextareaValue(event.target.value)}
          inputProps={{
            value: textareaValue,
          }}
          helperText={false}
        />
      </div>
      <div>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleAddFromTextarea}
          disabled={textareaValue === ''}
          style={{ width: '30%' }}>
          Add
        </Button>
      </div>
    </div>
  );
};
