import * as React from 'react';
import { useState, useEffect } from 'react';
import { useEditController, EditContextProvider, SimpleForm, useDataProvider, useNotify, Button } from 'react-admin';
import { IconButton, Typography } from '@material-ui/core';
import { EditPathMappingsStyle } from '../../style';
import TextField from '@material-ui/core/TextField';
import { useField } from 'react-final-form';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import { Toolbar, SaveButton, DeleteButton } from 'react-admin';

const PathMappingTextField = ({ name, label, onChange, value }) => {
  const {
    meta: { touched, error },
  } = useField(name);
  return (
    <TextField
      style={{ width: '40%', marginLeft: '16px', marginTop: '16px' }}
      name={name}
      label={label}
      onChange={onChange}
      error={!!(touched && error)}
      helperText={touched && error}
      value={value ? value[`${name}`] : ''}
    />
  );
};

const CustomToolbar = ({
  hasShow,
  basePath,
  hasCreate,
  hasEdit,
  hasList,
  isEdit = false,
  pathMappings,
  deletePathMappings,
  ...props
}) => {
  const classes = EditPathMappingsStyle();
  const [isLoading, setIsLoading] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const ids = deletePathMappings && deletePathMappings.map((pm) => pm.id);
  const handleSave = async () => {
    setIsLoading(true);
    if (pathMappings.length >= 1) {
      await Promise.all(
        pathMappings.map(async (pathMapping) => {
          await dataProvider
            .create('pathmapping', {
              data: { ...pathMapping },
            })
            .then(({ data }) => {})
            .catch((error) => {
              notify(`${error}`, 'warning');
            });
        }),
      )
        .then(() => setIsLoading(false))
        .catch((err) => console.log(err));
    }

    if (ids && ids.length >= 1) {
      await Promise.all(
        ids.map(async () => {
          await dataProvider
            .deleteMany('pathmapping', {
              ids,
            })
            .then(({ data }) => {})
            .catch((error) => {
              notify(`${error}`, 'warning');
            });
        }),
      )
        .then(() => setIsLoading(false))
        .catch((err) => console.log(err));
    }
    props.onCancel();
  };

  return (
    <Toolbar style={{ backgroundColor: 'transparent' }} {...props}>
      <SaveButton
        disabled={(pathMappings.length === 0 && deletePathMappings.length === 0) || isLoading}
        onSave={() => handleSave()}
        className={classes.button}
      />
      <Button className={classes.outlinedButton} icon={<ClearIcon />} label="Cancel" onClick={() => props.onCancel()} />
      {isEdit && <DeleteButton undoable={true} style={{ marginLeft: 'auto' }} />}
    </Toolbar>
  );
};

const EditPathMappings = ({ onCancel, ...props }) => {
  const classes = EditPathMappingsStyle();
  const controllerProps = useEditController(props);
  const [pathMappings, setPathMappings] = useState([]);
  const [deletePathMappings, setDeletePathMappings] = useState([]);
  const [pathMapping, setPathMapping] = useState({
    dest: '',
    src: '',
    config_id: props.id,
  });

  useEffect(() => {
    if (props.pathMappings) {
      setPathMappings(props.pathMappings);
    }
  }, [props.pathMappings]);

  const handleChange = (e, index) => {
    let newPathMappings = pathMappings;
    newPathMappings[index][`${e.target.name}`] = e.target.value;
    setPathMappings(newPathMappings);
  };

  const addPathMapping = () => {
    setPathMappings([...pathMappings, pathMapping]);
    setPathMapping((prevState) => {
      return { ...prevState, dest: '', src: '' };
    });
  };
  const deletePathMapping = (index, id) => {
    let newPathMappings = [...pathMappings];
    newPathMappings.splice(index, 1);
    if (id) {
      setDeletePathMappings([...deletePathMappings, props.pathMappings.filter((pm) => pm.id === id)[0]]);
    }
    setPathMappings([...newPathMappings]);
  };
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography style={{ marginTop: '18px', marginLeft: '18px' }} variant="h6">
          Path Mappings
        </Typography>
        <Button
          label="add"
          className={classes.outlinedButton}
          disabled={pathMapping.src === '' || pathMapping.dest === ''}
          style={{
            float: 'right',
            position: 'relative',
            top: '45px',
            right: '18px',
          }}
          onClick={() => addPathMapping()}
        />
      </div>
      <EditContextProvider value={controllerProps}>
        <SimpleForm
          className={classes.form}
          basePath={controllerProps.basePath}
          record={controllerProps.record}
          save={controllerProps.save}
          version={controllerProps.version}
          redirect="list"
          resource="reviews"
          toolbar={
            <CustomToolbar
              {...props}
              onCancel={() => onCancel()}
              deletePathMappings={deletePathMappings}
              pathMappings={[...pathMappings].filter((pm) => !pm.id)}
            />
          }>
          <div style={{ display: 'inline' }}>
            <PathMappingTextField
              onChange={(e) => setPathMapping({ ...pathMapping, src: e.target.value })}
              name="src"
              label="Source"
              value={pathMapping}></PathMappingTextField>
            <PathMappingTextField
              onChange={(e) => setPathMapping({ ...pathMapping, dest: e.target.value })}
              name="dest"
              label="Destination"
              value={pathMapping}></PathMappingTextField>
          </div>
          {pathMappings &&
            pathMappings.map((pm, index) => {
              return (
                <div key={index} style={{ display: 'inline' }}>
                  <PathMappingTextField
                    onChange={(e) => handleChange(e, index)}
                    name="src"
                    label="Source"
                    value={pm}></PathMappingTextField>
                  <PathMappingTextField
                    onChange={(e) => handleChange(e, index)}
                    name="dest"
                    label="Destination"
                    value={pm}></PathMappingTextField>
                  <IconButton
                    className={classes.deleteButton}
                    style={{
                      position: 'relative',
                      top: '20px',
                      right: '-15px',
                    }}
                    onClick={() => deletePathMapping(index, pm.id)}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              );
            })}
        </SimpleForm>
      </EditContextProvider>
    </div>
  );
};

export default EditPathMappings;
