import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { Fragment, useEffect, useState } from 'react';
import { ArrayField, Datagrid, DateField, TextField, FunctionField, Loading, useQuery, Pagination } from 'react-admin';
import { ChipField, ProgressField } from '../../src/components/index';
import keyBy from 'lodash/keyBy';
import moment from 'moment';

const useStyles = makeStyles({
  drawerPaper: {
    width: '20% !important',
  },
  tooltip: {
    fontSize: '14px',
  },
  table: {
    width: '60%',
    marginLeft: 'auto',
    display: 'flow',
  },
  rowCell: {
    borderBottom: '0px',
  },
  headerCell: {
    borderBottom: '0px',
  },
});

const EmailList = (props) => {
  const classes = useStyles();
  return (
    <ArrayField source="emails">
      <Datagrid classes={{ table: classes.table, rowCell: classes.rowCell, headerCell: classes.headerCell }}>
        <FunctionField
          render={(r) => (
            <>
              <span>{moment(r.updated).format('MM/DD/YYYY h:mm a')}</span>
              <br />
              <span> {r.email}</span>
            </>
          )}
        />
        <ChipField />
      </Datagrid>
    </ArrayField>
  );
};

export const NotificationList = (props) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [currentSort, setSort] = useState({ field: 'updated', order: 'DESC' });
  const [notifications, setNotifications] = useState(null);

  const { data, loading } = useQuery(
    {
      type: 'getList',
      resource: 'brsnotification',
      payload: {
        pagination: { page, perPage },
        sort: currentSort,
        filter: {
          brsjobdestination_id: props.destination_ids,
          latest: true,
        },
      },
    },
    {
      onFailure: (error) => {
        console.error(error);
      },
    },
  );

  useEffect(() => {
    setNotifications(data);
  }, [data]);

  if (loading) {
    return <Loading />;
  }

  return props.brs_job && notifications ? (
    <Fragment>
      <Datagrid
        data={keyBy(notifications, 'id')}
        ids={notifications.map(({ id }) => id)}
        currentSort={currentSort}
        setSort={(field, order) => setSort({ field, order })}
        hasBulkActions={false}
        expand={<EmailList />}>
        <TextField label="Destination" source="brsjobdestination.destination.call_letters" />
        <TextField label="TI Ver" source="traffic_instruction_version" />
        <DateField label="Last Updated" source="updated" showTime />
        {props.brs_job.trafficinstructions && props.brs_job.trafficinstructions.length > 0 ? (
          <TextField label="Status" source="status" />
        ) : (
          <FunctionField label="Status" render={(r) => ''} />
        )}
        {props.brs_job.trafficinstructions && props.brs_job.trafficinstructions.length > 0 ? (
          <ProgressField sortable={false} source="progress" type="destination" />
        ) : (
          <FunctionField label="Progress" render={(r) => ''} />
        )}
      </Datagrid>
      <Pagination
        page={page}
        perPage={perPage}
        setPage={setPage}
        setPerPage={setPerPage}
        total={props.destination_ids.length}
        rowsPerPageOptions={[25, 50, 100, 250, 500]}
      />
    </Fragment>
  ) : (
    <Loading />
  );
};
