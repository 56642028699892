import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Edit, SimpleForm, TextInput, useCreate, useNotify, useRecordContext, useUpdateMany } from 'react-admin';
import { createUpdateStyle } from '../style';
import { CustomToolbar } from './CustomToolBar';
import { DestinationListInput } from './DestinationListInput';
import { triggerAddonsUpdate } from '../helpers';

export const AddonDialog = (props) => {
  const resource = 'job';
  const record = useRecordContext();
  const { handleAddOnClose, handleRefresh, ...rest } = props;
  const classes = createUpdateStyle();
  const [create] = useCreate();
  const notify = useNotify();
  const [updateMany] = useUpdateMany();

  const onSave = async (data) => {
    //Calculate
    let ti = { version: 'N/A' };
    if (data.trafficinstructions.length > 0) {
      ti = data.trafficinstructions.reduce(function (prev, current) {
        return Number(prev.version) > Number(current.version) ? prev : current;
      });
    }
    const newNotification = {
      latest: true,
      status: 'Pending',
      progress: 0,
      traffic_instruction_version: ti.version,
    };

    if (!data.destinations_values) {
      notify(`We are unable to submit this Add-Ons please add a destination`, 'warning');
      return;
    }
    //WE GET THE DESTINATIONS WITH ACTIVE FALSE TO UPDATE TO ACTIVE TRUE
    //GET NOTIFICATIONS LATEST TRUE TO UPDATE TO LATEST FALSE
    const notificationsToUpdate = [];
    const addonsIds = [];
    const destinationsToUpdate = record.destinations
      .filter((d) => !d.active)
      .filter((destination) => {
        return data.destinations_values.includes(destination.destination.id);
      })
      .map((d) => {
        const notifications = d.notifications.filter((n) => n.latest);
        notificationsToUpdate.push(
          ...notifications.map((n) => {
            n.latest = false;
            return n;
          }),
        );
        addonsIds.push(d.destination.id);
        return { id: d.id, active: true };
      });

    // WE UPDATE NOTIFICATIONS LATEST TRUE TO FALSE
    if (notificationsToUpdate.length > 0) {
      const notificationsToUpdateIds = notificationsToUpdate.map((n) => n.id);
      await updateMany('brsnotification', notificationsToUpdateIds, notificationsToUpdate, {
        onSuccess: ({ data }) => {
          notify('Notifications Updated', 'info');
        },
        onFailure: (error) => {
          notify(`${error}`, 'warning');
        },
      });
    }

    // UPDATE DESTINATIONS ACTIVE FALSE TO TRUE
    if (destinationsToUpdate.length > 0) {
      const destinationsToUpdateIds = destinationsToUpdate.map((d) => d.id);
      await updateMany('brsjobdestination', destinationsToUpdateIds, destinationsToUpdate, {
        onSuccess: async ({ data }) => {
          // CREATE NEW NOTIFICATIONS WITH LATEST TRUE FOR EACH DESTINATION ID TO UPDATE
          for (let index = 0; index < destinationsToUpdateIds.length; index++) {
            newNotification.brsjobdestination_id = destinationsToUpdateIds[index];
            create('brsnotification', newNotification);
          }
          notify('Destinations Updated', 'info');
        },
        onFailure: (error) => {
          notify(`${error}`, 'warning');
        },
      });
    }

    //CREATE A NEW BRSJOBDESTINATION AND ATTACH A NEW NOTIFICATIONS WITH THE VALUE LATEST TRUE
    const newDestinations = (data.destinations_values || data.destinations.map((d) => d.destination.id)).filter(
      (id) => !record.destinations.map((d) => d.destination.id).includes(id),
    );
    if (newDestinations.length > 0) {
      await create(
        'brsjobdestination',
        newDestinations.map((id) => ({
          destination_id: id,
          brs_job_id: record.id,
          notifications: { data: newNotification },
        })),
        {
          onSuccess: ({ data }) => {
            notify('New Destinations Added', 'info');
            handleRefresh();
          },
          onFailure: (error) => {
            notify(`${error}`, 'warning');
            handleAddOnClose();
          },
        },
      );
    }

    //TRIGGER ADDONS UPDATE
    await triggerAddonsUpdate(record.id, record.job_id, [...newDestinations, ...addonsIds]);
    handleAddOnClose();
  };

  return (
    <Dialog open={true} onClose={handleAddOnClose} fullWidth maxWidth="lg">
      <DialogTitle>Add-Ons</DialogTitle>
      <DialogContent>
        <Card>
          <CardHeader>Traffic Update</CardHeader>
          <CardContent style={{ padding: 0 }}>
            <Edit
              className={classes.textField}
              resource={resource}
              redirect={false}
              actions={false}
              id={record.id}
              {...rest}>
              <SimpleForm
                toolbar={
                  <CustomToolbar
                    className={classes.modalToolbar}
                    handleCancelClick={handleAddOnClose}
                    saveButtonExtraProps={{ onSave }}
                  />
                }>
                <Card style={{ display: 'flex', width: '100%', justifyContent: 'flex-start' }}>
                  <TextInput
                    className={classes.textField}
                    source="request_id"
                    label="Request ID"
                    style={{ width: '37%', marginRight: '10rem' }}
                    disabled={true}
                  />
                  <TextInput
                    className={classes.textField}
                    source="estimate_id"
                    label="Estimate ID"
                    style={{ width: '37%' }}
                    disabled={true}
                  />
                </Card>
                <Card style={{ width: '100%' }}>
                  <DestinationListInput
                    brsDestinations={record.destinations}
                    source="destinations"
                    resource="destination"
                    sort={{ field: 'call_letters', order: 'ASC' }}
                    canDelete={false}
                    isCreate={false}
                  />
                </Card>
              </SimpleForm>
            </Edit>
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

AddonDialog.propTypes = {
  handleAddOnClose: PropTypes.func,
  resource: PropTypes.string,
  handleRefresh: PropTypes.func,
};
