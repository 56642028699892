import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  Button,
  useDataProvider,
  BooleanInput,
  AutocompleteArrayInput,
} from 'react-admin';
import { Drawer } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';
import { CustomToolbar } from '../../components/CustomToolBar';
import { EditWithToolbarStyle } from '../../style';
import { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import EditRecipient from './EditRecipient';
import * as React from 'react';

export const DestinationEdit = (props) => {
  const classes = EditWithToolbarStyle();
  const dataProvider = useDataProvider();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(null);
  const [allRecipients, setAllRecipients] = useState(null);
  const [recipients, setRecipients] = useState(null);
  useEffect(() => {
    const getDestinationRecipient = async () => {
      try {
        const { data: destinationRecipients } = await dataProvider.getList('destinationrecipient', {
          filter: {
            destination_id: props.id,
          },
        });
        const recipients = destinationRecipients.map((r) => {
          if (r.recipient_id) {
            const recipient = {
              ...r.recipient,
              destination_recipient_id: r.id,
            };
            return recipient;
          } else {
            return r;
          }
        });
        setRecipients(recipients);
        const { data: allRecipients } = await dataProvider.getList('recipient', {});
        const drIds = destinationRecipients.map((recipient) => recipient.recipient.id);
        const namesToDeleteSet = new Set(drIds);
        const newArr = allRecipients.filter((recipient) => {
          return !namesToDeleteSet.has(recipient.id);
        });
        setAllRecipients(newArr);
      } catch (error) {}
    };
    getDestinationRecipient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const transform = (data) => {
    const tags = data?.tags ? data.tags : [];
    return {
      ...data,
      tags: JSON.stringify(tags).replace('[', '{').replace(']', '}'),
    };
  };
  return (
    <div className={classes.card}>
      <a className={classes.breadcrumbLink} href="#/destination">
        {'< Destinations'}
      </a>{' '}
      <Edit mutationMode="pessimistic" transform={transform} title="Edit Destination" {...props} actions={false}>
        <SimpleForm toolbar={<CustomToolbar isEdit={true} {...props} />}>
          <Typography variant="h6">Edit Destination</Typography>
          <TextInput className={classes.textField} helperText={false} source="name" />
          <TextInput className={classes.textField} helperText={false} source="call_letters" label="Destination ID" />
          <ReferenceInput
            className={classes.textField}
            helperText={false}
            source="format_id"
            reference="format"
            perPage={1000}
            sort={{ field: 'name', order: 'ASC' }}>
            <SelectInput optionText="name" />
          </ReferenceInput>
          <ReferenceInput
            className={classes.textField}
            helperText={false}
            source="location_id"
            reference="location"
            perPage={1000}
            sort={{ field: 'name', order: 'ASC' }}>
            <SelectInput source="name" />
          </ReferenceInput>
          <AutocompleteArrayInput
            source="tags"
            allowEmpty={true}
            choices={[
              { id: 'CABLE_REEL_SA', name: 'SA Reel' },
              { id: 'CABLE_REEL_1', name: 'Cable Reels' },
            ]}
          />
          <BooleanInput className={classes.textField} label="SD" source="sd" helperText={false} />
          <BooleanInput className={classes.textField} label="XML Sidecar" source="xml_sidecar" helperText={false} />
          <div>
            <Button
              className={classes.editButton}
              style={{ marginTop: '14px', marginLeft: '0px' }}
              label="NOTIFICATIONS"
              onClick={() => setIsOpen(true)}>
              <EditIcon />
            </Button>
          </div>
        </SimpleForm>
      </Edit>
      <Drawer
        variant="persistent"
        open={isOpen}
        anchor="right"
        onClose={handleClose}
        style={{ width: '10rem' }}
        PaperProps={{ style: { width: '30%' } }}
        classes={{
          modal: 'width: "10rem"',
          paper: classes.drawerPaper,
        }}>
        <EditRecipient
          id={props.id}
          onCancel={handleClose}
          resource={props.resource}
          basePath={props.basePath}
          recipients={recipients}
          allRecipients={allRecipients}
        />
      </Drawer>
    </div>
  );
};
