import { Button, Card, CardActions, CardContent, CardHeader } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  Loading,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
  useRedirect,
  useRefresh,
} from 'react-admin';
import { AddonDialog, NotificationList, TrafficUpdateDialog } from '../../components';
import { createUpdateStyle } from '../../style';
import { BroadcastRequestList } from '../../components/BroadcastRequestList';

const BrsTitle = () => {
  const record = useRecordContext();
  return <span>Job Detail {record.request_id}</span>;
};

const JobDetails = ({ classes }) => {
  const record = useRecordContext();
  const [addOnOpen, setAddOnOpen] = useState(false);
  const [trafficUpdateOpen, setTrafficUpdateOpen] = useState(false);
  const [destinations, setDestinations] = useState(record.destinations);

  const redirect = useRedirect();
  const refresh = useRefresh();

  const handleAddOnOpen = () => {
    setAddOnOpen(true);
  };

  const handleRefresh = () => {
    refresh();
  };

  const handleAddOnClose = () => {
    setAddOnOpen(false);
    redirect(false);
  };

  const handleTrafficUpdateOpen = () => {
    redirect('edit', '/traffic-instructions', record.id, {}, { record: { estimate_id: record.estimate_id } });
  };

  const handleTrafficUpdateClose = () => {
    setTrafficUpdateOpen(false);
    redirect(false);
  };

  useEffect(() => {
    if (record.destinations) {
      setDestinations(record.destinations);
    }
  }, [record]);

  return (
    <SimpleShowLayout>
      <Card>
        <CardHeader
          title={
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%' }}>
              <div style={{ width: '25%' }}>
                Request: <TextField style={{ fontSize: 20 }} source="request_id" />
              </div>
              <div style={{ width: '20%' }}>
                Estimate: <TextField style={{ fontSize: 20 }} source="estimate_id" />
              </div>
              <div style={{ width: '20%' }}>
                DST: <TextField style={{ fontSize: 20 }} source="market_type" />
              </div>
            </div>
          }
        />
        <CardActions style={{ justifyContent: 'flex-end' }}>
          <Button
            className={classes.showButton}
            variant="outlined"
            disabled={record?.step_status?.['MEDIA'] === 'ERROR' || record?.step_status?.['MEDIA'] === 'IN_PROGRESS'}
            onClick={handleAddOnOpen}>
            Add-Ons
          </Button>
          <Button
            color="secondary"
            variant="outlined"
            disabled={record?.step_status?.['MEDIA'] === 'IN_PROGRESS' || record?.step_status?.['MEDIA'] === 'ERROR'}
            onClick={handleTrafficUpdateOpen}>
            Traffic Update
          </Button>
        </CardActions>
        <CardContent>
          <BroadcastRequestList brs_job={record} />
        </CardContent>
      </Card>
      <Card>
        <CardHeader title="Notifications" />
        {destinations?.length > 0 ? (
          <CardContent>
            <NotificationList brs_job={record} destination_ids={destinations.map((destination) => destination.id)} />
          </CardContent>
        ) : (
          <Loading />
        )}
      </Card>
      <Card>
        <CardHeader title="Traffic Instructions" />
        <CardContent>
          <ArrayField source="trafficinstructions">
            <Datagrid hasBulkActions={false}>
              <TextField sortable={true} source="version" />
              <DateField source="created" label="Date" showTime />
              <FunctionField
                label="Name"
                render={(r) => (
                  <a
                    href={`${window.REACT_APP_API_URL}/download-traffic-instructions?s3key=${encodeURIComponent(r.filepath)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}>
                    {r.name}
                  </a>
                )}
              />
            </Datagrid>
          </ArrayField>
        </CardContent>
      </Card>
      {addOnOpen && <AddonDialog handleAddOnClose={handleAddOnClose} handleRefresh={handleRefresh} />}
      {trafficUpdateOpen && (
        <TrafficUpdateDialog handleTrafficUpdateClose={handleTrafficUpdateClose} handleRefresh={handleRefresh} />
      )}
    </SimpleShowLayout>
  );
};

export const BrsShow = (props) => {
  const classes = createUpdateStyle();

  return (
    <div className={classes.card} style={{ width: '100%' }}>
      <a className={classes.breadcrumbLink} href="#/brs">
        {'< BRS Jobs'}
      </a>
      <Show resource="brsjob" basePath={'/brs'} title={<BrsTitle />} id={props.match.params.id}>
        <JobDetails classes={classes} />
      </Show>
    </div>
  );
};
