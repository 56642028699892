import { Card } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { useInput } from 'ra-core';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AutocompleteInput, ChipField, TextInput, useGetList, useNotify } from 'react-admin';
import { useForm } from 'react-final-form';
import difference from 'lodash/difference';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  listContainer: {
    width: '50%',
    padding: theme.spacing(1),
  },
  list: {
    height: theme.spacing(50),
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
  },
  listHeader: {
    display: 'block',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
  },
  textField: {
    width: '100%',
    marginTop: 0,
  },
  textAreaField: {
    width: '100%',
    height: '100%',
    marginTop: 0,
  },
  hiddenTextField: {
    '& .MuiFilledInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiFormHelperText-contained': {
      display: 'none',
    },
    display: 'none',
    visibility: 'hidden',
  },
  autocomplete: {
    width: '100%',
  },
  button: {
    margin: theme.spacing(1),
    width: '90%',
  },
  textarea: {
    height: '100%',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2.5),
  },
  textareaActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
}));

export const DestinationListInput = (props) => {
  const { resource, source, sort, label, style, showDestinationTextArea, canDelete, brsDestinations, isCreate } = props;
  const { data, loading } = useGetList(resource, { page: 1, perPage: 10000 }, sort);
  const autocompleteRef = useRef(null);
  const [searchText, setSearchText] = useState('');
  let choices = [];
  if (canDelete && !isCreate) {
    choices = brsDestinations.map((choice) => {
      return {
        id: choice.destination.id,
        name: choice.destination.name,
        call_letters: choice.destination.call_letters,
      };
    });
  } else {
    choices = Object.values(data || {}).map((choice) => {
      return {
        id: choice.id,
        name: choice.name,
        call_letters: choice.call_letters,
      };
    });
  }

  const form = useForm();
  const classes = useStyles();
  const notify = useNotify();

  const { input, meta } = useInput({
    source,
  });

  const mapInputValueToDestinations = (inputValue) => {
    return inputValue
      .filter((d) => d.active)
      .map((inputValueItem) => {
        return {
          id: inputValueItem.destination.id,
          name: inputValueItem.destination.name,
          call_letters: inputValueItem.destination.call_letters,
          active: inputValueItem.active,
        };
      });
  };

  const initialSelectedChoices = mapInputValueToDestinations(input.value || []);
  const [selectedChoices, setSelectedChoices] = useState(initialSelectedChoices);
  const [textareaValue, setTextareaValue] = useState('');
  const availableChoices = useMemo(() => {
    return choices.filter((choice) => !selectedChoices.map((sc) => sc.id).includes(choice.id));
  }, [choices, selectedChoices]);

  useEffect(() => {
    setSelectedChoices(mapInputValueToDestinations(input.value || []));
  }, [input.value]);

  const handleAdd = (selectedChoiceId) => {
    const selectedChoiceObject = choices.find((choice) => choice.id === selectedChoiceId);
    if (selectedChoiceObject) {
      const currentSelectedChoices = [...new Set([...selectedChoices, selectedChoiceObject])];
      setSelectedChoices(currentSelectedChoices);
      form.change(
        `${source}_values`,
        currentSelectedChoices.map((choice) => choice.id),
      );
    }
    autocompleteRef.current.querySelector('input').blur();
  };

  const handleRemove = (selectedChoiceId) => {
    const currentSelectedChoices = selectedChoices.filter((choice) => choice.id !== selectedChoiceId);
    setSelectedChoices(currentSelectedChoices);
    if (currentSelectedChoices.filter((choice) => choice.active).length === 0) {
      form.change('disabled_button', true);
    } else {
      form.change('disabled_button', false);
    }
    form.change(
      `${source}_values`,
      currentSelectedChoices.map((choice) => choice.id),
    );
  };

  const handleAddFromTextarea = () => {
    const selectedChoiceIds = textareaValue
      .split(/,|\n/)
      .map((callLetters) => callLetters.trim())
      .filter(Boolean);
    const selectedChoiceObjects = choices.filter((choice) =>
      selectedChoiceIds.map((sc) => sc.toLowerCase()).includes(choice.call_letters.toLowerCase()),
    );
    const invalideDestinations = difference(
      selectedChoiceIds,
      choices.map((c) => c.call_letters),
    );
    const alreadyDestinations = [];
    if (invalideDestinations.length > 0) {
      notify(
        `Some of the destination(s) you entered ${selectedChoiceIds.length < 2 ? 'is not' : 'are not'} valid: ${invalideDestinations.map((destination) => destination)}`,
        'warning',
        { autoHideDuration: 5000 },
      );
    }
    selectedChoiceObjects
      .map((sc) => sc.id)
      .forEach((id) => {
        selectedChoices.forEach((choice) => {
          if (choice.id === id) {
            alreadyDestinations.push(choice.call_letters);
          }
        });
      });
    if (selectedChoiceObjects.map((sc) => sc.id).some((id) => selectedChoices.map((sc) => sc.id).includes(id))) {
      notify(
        `Some of the destination(s) you entered ${selectedChoiceIds.length < 2 ? 'is' : 'are'} already in the list: ${alreadyDestinations.map((destination) => destination)}`,
        'warning',
        { autoHideDuration: 5000, multiLine: true },
      );
    }
    // Prevent duplicates in the selectedChoices array
    const currentSelectedChoices = [
      ...new Set([
        ...selectedChoices,
        ...selectedChoiceObjects.filter((choice) => !selectedChoices.map((sc) => sc.id).includes(choice.id)),
      ]),
    ];
    setSelectedChoices(currentSelectedChoices);
    setTextareaValue([...invalideDestinations, ...alreadyDestinations].toString());
    form.change(
      `${source}_values`,
      currentSelectedChoices.map((choice) => choice.id),
    );
  };

  const handleTextareaRemove = () => {
    const selectedChoiceIds = textareaValue.split(/,|\n/).map((callLetters) => callLetters.trim());
    const currentSelectedChoices = selectedChoices.filter((choice) => !selectedChoiceIds.includes(choice.call_letters));
    setSelectedChoices(currentSelectedChoices);
    setTextareaValue('');
    form.change(
      `${source}_values`,
      currentSelectedChoices.map((choice) => choice.id),
    );
  };

  const isBrsDestination = (choice) => {
    const destinationExist = brsDestinations.find((destination) => destination.destination.id === choice.id);
    return destinationExist ? true : false;
  };

  return (
    <div className={classes.container} style={style}>
      <Typography variant="subtitle1">{label}</Typography>
      <Card className={classes.root} variant="outlined">
        <div className={classes.listContainer} style={!showDestinationTextArea ? { width: '100%' } : {}}>
          <div className={classes.listHeader} ref={autocompleteRef}>
            {canDelete && !isCreate ? (
              <TextInput
                onChange={(e) => {
                  e.target ? setSearchText(e.target.value) : setSearchText('');
                }}
                source="destination"
                label="Search"
                resettable
                inputProps={{
                  value: searchText,
                }}
                allowEmpty={false}
                className={classes.autocomplete}
              />
            ) : (
              <AutocompleteInput
                className={classes.autocomplete}
                choices={availableChoices}
                source="destination_id_autocomplete"
                helperText={false}
                allowEmpty={true}
                loading={loading}
                input={{
                  ...input,
                  onChange: handleAdd,
                }}
                meta={meta}
                translateChoice={false}
                label="Search"
                resettable
                optionText={(choice) =>
                  choice && choice.name && choice.call_letters ? `${choice.call_letters} (${choice.name})` : ''
                }
              />
            )}
          </div>
          <div className={classes.list}>
            {!canDelete
              ? selectedChoices
                  .filter((d) => !d.hasOwnProperty('active'))
                  .map((choice) => {
                    return (
                      <ChipField
                        key={choice.id}
                        record={choice}
                        source="call_letters"
                        color="primary"
                        onDelete={() => handleRemove(choice.id)}
                      />
                    );
                  })
              : selectedChoices.map((choice) => {
                  if (isCreate) {
                    return (
                      <ChipField
                        key={choice.id}
                        record={choice}
                        source="call_letters"
                        color="primary"
                        onDelete={() => handleRemove(choice.id)}
                      />
                    );
                  } else if (searchText === '' && choice.active) {
                    return (
                      <ChipField
                        key={choice.id}
                        record={choice}
                        source="call_letters"
                        color="primary"
                        onDelete={() => handleRemove(choice.id)}
                      />
                    );
                  } else if (choice.call_letters.toLowerCase().includes(searchText.toLowerCase()) && choice.active) {
                    return (
                      <ChipField
                        key={choice.id}
                        record={choice}
                        source="call_letters"
                        color="primary"
                        onDelete={isBrsDestination(choice) ? () => handleRemove(choice.id) : undefined}
                      />
                    );
                  }
                })}
          </div>
        </div>
        {showDestinationTextArea && (
          <div className={classes.listContainer}>
            <div className={classes.textarea}>
              <TextInput
                multiline
                source="destination_id_textarea"
                label="Destination IDs"
                minRows={23}
                className={classes.textAreaField}
                placeholder="Enter multiple comma separated destinations ids"
                onChange={(event) => setTextareaValue(event.target.value)}
                inputProps={{
                  value: textareaValue,
                }}
                helperText={false}
              />
            </div>
            <div className={classes.textareaActions}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleAddFromTextarea}
                disabled={textareaValue === ''}
                style={{ width: '30%' }}>
                Add
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleTextareaRemove}
                disabled={textareaValue === ''}
                style={{ width: '30%' }}>
                Remove
              </Button>
            </div>
          </div>
        )}
      </Card>
    </div>
  );
};

DestinationListInput.propTypes = {
  resource: PropTypes.string.isRequired,
  source: PropTypes.string,
  sort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  style: PropTypes.object,
  className: PropTypes.string,
  loading: PropTypes.bool,
  showDestinationTextArea: PropTypes.bool,
  canDelete: PropTypes.bool,
};

DestinationListInput.defaultProps = {
  style: {},
  choices: [],
  label: 'Destinations',
  showDestinationTextArea: true,
  canDelete: true,
};
