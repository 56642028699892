import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import { Button, DeleteButton, SaveButton, Toolbar, useNotify, useRedirect } from 'react-admin';
//import { EditWithToolbarStyle } from '../style';
//import { useForm } from 'react-final-form';
import { createEmailTemplate, updateEmailTemplate } from '../helpers';

const useStyles = makeStyles({
  cancelButton: {
    backgroundColor: 'transparent',
    marginLeft: '16px',
    border: '1px solid #3CE7EA',
    color: '#3CE7EA',
    borderRadius: 'unset',
    padding: '4px 8px 4px 8px',
    opacity: 0.9,
    '&:hover': {
      backgroundColor: '#3CE7EA',
      color: '#2A2A2A',
      opacity: 1,
      boxShadow: 'none',
    },
    '& svg': {
      marginRight: '6px',
    },
  },
  button: {
    backgroundColor: '#3CE7EA',
    color: '#2A2A2A',
    borderRadius: 'unset',
    padding: '4px 8px 4px 8px',
    opacity: 0.9,
    '&:hover': {
      backgroundColor: '#3CE7EA',
      color: '#2A2A2A',
      opacity: 1,
      boxShadow: 'none',
    },
    '& svg': {
      marginRight: '6px',
    },
  },
});

export const EmailEditorToolbar = ({
  hasShow,
  basePath,
  hasCreate,
  hasEdit,
  hasList,
  isEdit = false,
  isDisabled = false,
  saveButtonExtraProps = {},
  handleCancelClick,
  ...props
}) => {
  //const dataProvider = useDataProvider();
  //const classes = EditWithToolbarStyle();
  const redirect = useRedirect();
  const handleClick = () => {
    redirect(`/${props.resource}`);
  };
  const notify = useNotify();
  const handleSave = async (values) => {
    const email_template = { _type: values.type, id: values.id, body: values.body, subject: values.subject };
    if (isEdit) {
      try {
        await updateEmailTemplate(email_template, notify);
        notify('Email Template Updated Successfully', 'info');
      } catch (error) {
        notify('We are not able to Update Email Template', 'warning');
      }
    } else {
      try {
        await createEmailTemplate(email_template, notify);
        notify('Create Email Template Successfully', 'info');
      } catch (error) {
        notify('We are not able to Create Email Template', 'warning');
      }
    }
    redirect(`/${props.resource}`);
  };

  return (
    <Toolbar {...props}>
      <SaveButton disabled={props.saving || isDisabled} onSave={handleSave} className={useStyles().button} />
      <Button className={useStyles().cancelButton} icon={<ClearIcon />} label="Cancel" onClick={handleClick} />
      {isEdit && <DeleteButton undoable={true} style={{ marginLeft: 'auto' }} />}
    </Toolbar>
  );
};
