import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import { Button, DeleteButton, SaveButton, Toolbar, useRedirect } from 'react-admin';

const useStyles = makeStyles({
  cancelButton: {
    backgroundColor: 'transparent',
    marginLeft: '16px',
    border: '1px solid #3CE7EA',
    color: '#3CE7EA',
    borderRadius: 'unset',
    padding: '4px 8px 4px 8px',
    opacity: 0.9,
    '&:hover': {
      backgroundColor: '#3CE7EA',
      color: '#2A2A2A',
      opacity: 1,
      boxShadow: 'none',
    },
    '& svg': {
      marginRight: '6px',
    },
  },
  button: {
    backgroundColor: '#3CE7EA',
    color: '#2A2A2A',
    borderRadius: 'unset',
    padding: '4px 8px 4px 8px',
    opacity: 0.9,
    '&:hover': {
      backgroundColor: '#3CE7EA',
      color: '#2A2A2A',
      opacity: 1,
      boxShadow: 'none',
    },
    '& svg': {
      marginRight: '6px',
    },
  },
});

export const CustomToolbar = ({
  hasShow,
  basePath,
  hasCreate,
  hasEdit,
  hasList,
  isEdit = false,
  isDisabled = false,
  saveButtonExtraProps = {},
  handleCancelClick,
  label = 'Save',
  ...props
}) => {
  const redirect = useRedirect();
  return (
    <Toolbar {...props}>
      <SaveButton disabled={isDisabled} label={label} className={useStyles().button} {...saveButtonExtraProps} />
      <Button
        className={useStyles().cancelButton}
        icon={<ClearIcon />}
        label="Cancel"
        onClick={() => {
          if (typeof handleCancelClick === 'function') {
            handleCancelClick();
          } else {
            redirect(`/${props.resource}`);
          }
        }}
      />
      {isEdit && <DeleteButton mutationMode="undoable" style={{ marginLeft: 'auto' }} />}
    </Toolbar>
  );
};
