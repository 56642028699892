import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#808080',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#66be72',
    animation: '$pulse 2s infinite',
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(0.95)',
    },

    '70%': {
      transform: 'scale(1.18)',
    },

    '100%': {
      transform: 'scale(0.95)',
    },
  },
  circle: {
    width: 16,
    height: 16,
    borderRadius: '50%',
    color: '#808080',
    backgroundColor: 'currentColor',
  },
  draft: {
    width: 16,
    height: 16,
    borderRadius: '50%',
    color: '#808080',
    backgroundColor: 'currentColor',
  },
  caution: {
    width: 16,
    height: 16,
    borderRadius: '50%',
    color: '#ff9800',
    backgroundColor: 'currentColor',
  },
  completed: {
    width: 16,
    height: 16,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
    color: '#66be72',
  },
  inProgress: {
    width: 16,
    height: 16,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
    color: '#fbb877',
    transition: 'width 2s, height 4s',
  },
  fail: {
    width: 16,
    height: 16,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
    color: '#f7726d',
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPaper-root': {
      background: 'transparent',
    },
    width: '100%',
    margin: 'auto',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps(jobStep) {
  const steps = [
    { label: '', status: 'draft' },
    { label: '', status: 'draft' },
    { label: '', status: 'draft' },
  ];
  if (jobStep) {
    Object.keys(jobStep).forEach((key, index) => {
      if (steps[index]) {
        steps[index].status = jobStep[key];
      }
    });
  }
  return steps;
}

const getStateClass = (icon, jobStep) => {
  let status = {
    PREFLIGHT: getActiveState(jobStep.PREFLIGHT),
    MEDIA: getActiveState(jobStep.MEDIA),
    NOTIFICATIONS: getActiveState(jobStep.NOTIFICATIONS),
  };
  switch (icon) {
    case 1:
      return status.PREFLIGHT;
    case 2:
      return status.MEDIA;
    case 3:
      return status.NOTIFICATIONS;
    default:
      return;
  }
};

const getActiveState = (step_status) => {
  if (step_status === 'IN_PROGRESS') {
    return 'inProgress';
  }
  if (step_status === 'CAUTION') {
    return 'caution';
  }
  if (step_status === 'COMPLETE') {
    return 'completed';
  }
  if (step_status === 'ERROR') {
    return 'fail';
  }
  return 'draft';
};

const isErrorInState = (step_status) => {
  return step_status.includes('ERROR');
};
const isStateComplete = (step_status) => {
  return step_status.includes('COMPLETE');
};

const getActiveStep = (step_status) => {
  if (step_status && step_status.includes('PREFLIGHT')) {
    if (isErrorInState(step_status)) {
      return null;
    }
    return 0;
  }
  if (step_status && step_status.includes('MEDIA')) {
    if (isErrorInState(step_status)) {
      return null;
    }
    return 1;
  }
  if (step_status && step_status.includes('NOTIFICATIONS')) {
    if (isErrorInState(step_status)) {
      return null;
    }
    if (isStateComplete(step_status)) {
      return null;
    }
    return 2;
  }
};

const getBarActiveColor = (status) => {
  switch (getActiveState(status)) {
    case 'completed':
      return '#66be72';
    case 'inProgress':
      return '#fbb877';
    case 'fail':
      return '#f7726d';
    case 'caution':
      return '#ff9800';
    default:
      return '#808080';
  }
};

const useConnectorStyles = makeStyles({
  stepConnector: (steps) => {
    const styles = {};
    steps.forEach(({ status }, index) => {
      if (index < steps.length - 1) {
        styles[`&:nth-child(${2 * index + 2})`] = {
          color: getBarActiveColor(status),
        };
      }
    });
    return styles;
  },
  stepConnectorLine: {
    borderTopWidth: 3,
    borderRadius: 1,
    borderColor: 'currentColor',
  },
});

export const StepperFieldJobBrs = (props) => {
  const classes = useStyles();
  const steps = getSteps(props.record?.step_history);
  const connectorClasses = useConnectorStyles(steps);
  const record = props.record;
  const QontoStepIcon = (props) => {
    const classes = useQontoStepIconStyles();
    const { active, icon } = props;
    const className = record?.step_status ? getStateClass(icon, record.step_history) : 'circle';
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })}>
        <div className={classes[className]} />
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <Stepper
        {...props}
        activeStep={record?.step_status ? getActiveStep(record.step_status) : null}
        nonLinear
        connector={
          <StepConnector
            classes={{
              root: connectorClasses.stepConnector,
              line: connectorClasses.stepConnectorLine,
            }}
          />
        }>
        {steps.map((step, id) => (
          <Step key={id} style={{ padding: '0px' }}>
            <StepLabel StepIconComponent={QontoStepIcon}>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};
