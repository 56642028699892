import { useState } from 'react';
import { Create, SimpleForm, TextInput, PasswordInput, BooleanInput, SelectInput } from 'react-admin';
import { createUpdateStyle } from '../../style';
import Typography from '@material-ui/core/Typography';
import { CustomToolbar } from '../../components/CustomToolBar';

export const LocationCreate = (props) => {
  const classes = createUpdateStyle();
  const [transferType, setTransferType] = useState('local');
  const transform = (data) => {
    if (data.type === 'sftp') {
      const pk = data.sftplocation.pk ? data.sftplocation.pk.replaceAll(/(\r\n|\n|\r)/g, '$NEW_LINE$') : '';
      return {
        ...data,
        sftplocation: {
          ...data.sftplocation,
          pk,
        },
      };
    } else {
      return { ...data };
    }
  };
  return (
    <div className={classes.card}>
      <a className={classes.breadcrumbLink} href="#/location">
        {'< Locations'}
      </a>
      <Create transform={transform} title="Add Location" {...props}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar {...props} />}>
          <Typography variant="h6">Add Location</Typography>
          <TextInput className={classes.textField} source="name" helperText={false} />
          <SelectInput
            className={classes.textField}
            source="type"
            helperText={false}
            defaultValue={transferType}
            onChange={(e) => setTransferType(e.target.value)}
            optionValue="id"
            choices={[
              { id: 'local', name: 'LOCAL' },
              { id: 'ftp', name: 'FTP' },
              { id: 'sftp', name: 'SFTP' },
              { id: 'fasp', name: 'FASP' },
            ]}
          />
          <TextInput
            helperText={false}
            className={classes.textField}
            source={
              transferType === 'ftp' || transferType === 'sftp' || transferType === 'fasp'
                ? `${transferType}location.host`
                : transferType === 'local' && 'locallocation.url'
            }
            label={transferType === 'local' ? 'Url' : 'Host'}
          />
          <TextInput
            className={classes.textField}
            label="Username"
            source={
              transferType === 'ftp' || transferType === 'sftp' || transferType === 'fasp'
                ? `${transferType}location.user`
                : transferType === 'local' && 'locallocation.username'
            }
            helperText={false}
          />
          <PasswordInput
            className={classes.textField}
            label="Password"
            source={`${transferType}location.password`}
            helperText={false}
          />
          {transferType === 'sftp' && (
            <TextInput
              className={classes.textField}
              label="Token"
              source={`${transferType}location.pk`}
              helperText={false}
              multiline
            />
          )}
          <TextInput
            className={classes.textField}
            label="Path"
            source={`${transferType}location.destination_path`}
            helperText={false}
          />
          <BooleanInput className={classes.textField} label="Bundle Files" source="bundle_files" helperText={false} />
          {transferType === 'ftp' && (
            <BooleanInput
              className={classes.textField}
              label="TLS"
              source={`${transferType}location.tls`}
              helperText={false}
            />
          )}
        </SimpleForm>
      </Create>
    </div>
  );
};
