import React, { useState, useEffect } from 'react';
import { MenuItemLink } from 'react-admin';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CachedIcon from '@material-ui/icons/Cached';
import DescriptionIcon from '@material-ui/icons/Description';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import BusinessIcon from '@material-ui/icons/Business';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import Email from '@material-ui/icons/Email';
import { getRole } from '../../authProvider/authProvider';
import { CURRENT_USER_API_PATH } from '../../constants';
import axios from 'axios';

const Menu = () => {
  //   const roles =
  //     window.CADENT_SSO_V2 === "true"
  //       ? getRolesFromAuth(Auth.userData)
  //       : localStorage.getItem("app")
  //         ? JSON.parse(
  //             CryptoJS.AES.decrypt(
  //               localStorage.getItem("app"),
  //               process.env.REACT_APP_APPLICATION_ID,
  //             ).toString(CryptoJS.enc.Utf8),
  //           ).roles
  //         : null;
  const [roles, setRoles] = useState([]);

  const getUserRoles = async () => {
    const currentUserApiUrl = `${window.REACT_APP_API_URL}${CURRENT_USER_API_PATH}`;
    const userToken = localStorage.getItem('token');

    try {
      const res = await axios.post(currentUserApiUrl, null, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setRoles(res.data.roles);
      return res.data.roles || [];
    } catch (error) {
      console.error(error);
      return []; // Return an empty array in case of error
    }
  };
  useEffect(() => {
    getUserRoles();
  }, []);

  const role = getRole(roles);

  return (
    <div style={{ marginTop: '24px' }}>
      {roles && (
        <>
          {!['ar-user', 'brs-user', 'ar-brs-user'].includes(role) && (
            <MenuItemLink to="/job" primaryText="Jobs" leftIcon={<DashboardIcon />} />
          )}
          {role !== 'brs-user' && <MenuItemLink to="/ar" primaryText="AR" leftIcon={<DashboardIcon />} />}
          {role !== 'ar-user' && <MenuItemLink to="/brs" primaryText="BRS" leftIcon={<DashboardIcon />} />}
          {!['dh-user', 'ar-user', 'brs-user', 'ar-brs-user'].includes(role) && (
            <>
              <MenuItemLink to="/transcoder" primaryText="Transcoders" leftIcon={<CachedIcon />} />
              <MenuItemLink to="/format" primaryText="Formats" leftIcon={<DescriptionIcon />} />
              <MenuItemLink to="/location" primaryText="Locations" leftIcon={<LocationOnIcon />} />
              <MenuItemLink to="/destination" primaryText="Destinations" leftIcon={<BusinessIcon />} />
              <MenuItemLink to="/emailtemplate" primaryText="Email Templates" leftIcon={<Email />} />
              <MenuItemLink to="/recipient" primaryText="Recipients" leftIcon={<ContactMailIcon />} />
            </>
          )}

          {!['ar-user'].includes(role) && (
            <>
              <MenuItemLink to="/brand" primaryText="Brands" leftIcon={<DashboardIcon />} />
              <MenuItemLink to="/inventorysource" primaryText="Inventory Sources" leftIcon={<DashboardIcon />} />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Menu;
