import {
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  CREATE,
  UPDATE,
  DELETE,
  UPDATE_MANY,
  DELETE_MANY,
} from './fetchActions';

const sanitizeResource = (data = {}) => {
  const result = Object.keys(data).reduce((acc, key) => {
    if (key.startsWith('_')) {
      return acc;
    }

    const dataKey = data[key];

    if (dataKey === null || dataKey === undefined) {
      return acc;
    }
    if (Array.isArray(dataKey)) {
      if (dataKey[0] && typeof dataKey[0] === 'object') {
        // if var is an array of reference objects with id properties
        if (dataKey[0].id != null) {
          return {
            ...acc,
            [key]: dataKey.map(sanitizeResource),
            [`${key}Ids`]: dataKey.map((d) => d.id),
          };
        } else {
          return {
            ...acc,
            [key]: dataKey.map(sanitizeResource),
          };
        }
      } else {
        return { ...acc, [key]: dataKey };
      }
    }

    if (typeof dataKey === 'object') {
      return {
        ...acc,
        ...(dataKey &&
          dataKey.id && {
            [`${key}.id`]: dataKey.id,
          }),
        [key]: sanitizeResource(dataKey),
      };
    }

    return { ...acc, [key]: dataKey };
  }, {});

  return result;
};

export const customGetResponseParser =
  (introspectionResults) => (aorFetchType, resource, params, queryType) => (res) => {
    const response = res.data;

    // ITS NEED FOR AVOID
    if (aorFetchType === CREATE) {
      if (response.data && response.data.returning.length === 0) {
        response.data.returning.push({ id: 'fix' });
      } else if (response.data && response.data.returning.length === 1) {
        if (
          (!response.data.returning[0].id && response.data.returning[0].ftp_location_id) ||
          (!response.data.returning[0].id && response.data.returning[0].sftp_location_id) ||
          (!response.data.returning[0].id && response.data.returning[0].fasp_location_id) ||
          (!response.data.returning[0].id && response.data.returning[0].local_location_id)
        ) {
          response.data.returning[0].id = 'fix';
        }
        if (!response.data.returning[0].id && response.data.returning[0].transcoder_id) {
          response.data.returning[0].id = 'fix';
        }
      }
    }

    if (aorFetchType === UPDATE) {
      if (response.data && response.data.returning.length === 0) {
        response.data.returning.push({ id: 'fix' });
      } else if (response.data && response.data.returning.length === 1) {
        if (
          (!response.data.returning[0].id && response.data.returning[0].ftp_location_id) ||
          (!response.data.returning[0].id && response.data.returning[0].sftp_location_id) ||
          (!response.data.returning[0].id && response.data.returning[0].fasp_location_id) ||
          (!response.data.returning[0].id && response.data.returning[0].local_location_id)
        ) {
          response.data.returning[0].id = 'fix';
        }
        if (!response.data.returning[0].id && response.data.returning[0].transcoder_id) {
          response.data.returning[0].id = 'fix';
        }
      }
    }

    switch (aorFetchType) {
      case GET_MANY_REFERENCE:
      case GET_LIST:
        return {
          data: response.items.map(sanitizeResource),
          ids: response.items.map(sanitizeResource).map((item) => item.id),
          total: response.total.aggregate.count,
        };

      case GET_MANY:
        return { data: response.items.map(sanitizeResource) };

      case GET_ONE:
        return { data: sanitizeResource(response.returning[0]) };

      case CREATE:
      case UPDATE:
      case DELETE:
        return { data: sanitizeResource(response.data.returning[0]) };
      case UPDATE_MANY:
        return { data: response.data[0].returning.map((x) => x.id) };
      case DELETE_MANY:
        return { data: response.data.returning.map((x) => x.id) };

      default:
        throw Error('Expected a propper fetchType, got: ', aorFetchType);
    }
  };
