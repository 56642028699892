import React, { useState, useEffect } from 'react';
import { SelectInput, Button, SimpleForm, useNotify, useRefresh } from 'react-admin';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { DateRangePicker } from 'react-dates';
import { STRATA_SEARCH_ORDERS } from 'src/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';

export const BrsCreateStrataModal = ({ modalOpen, setModalOpen }) => {
  //const sampleData = ['5f0246e8-a4a6-419b-9155-b6ceca678502', '0001', '0002', '0003', '0004', '0005'];
  const classes = BrsCreateStrataModalStyle();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [ordersList, setOrdersList] = useState([]);
  const [estimateIds, setEstimateIds] = useState([]);
  const [estimateData, setEstimateData] = useState({
    selectedEstimateId: null,
    selectedDestinations: [],
    selectedUUIDs: {}
  });
  const notify = useNotify();
  const history = useHistory();
  const refresh = useRefresh();

  // Store estimateData in local storage whenever it changes
  useEffect(() => {
    localStorage.setItem('estimateData', JSON.stringify(estimateData));
  }, [estimateData]);

  const handleSearch = async () => {
    const strataSearchOrdersUrl = `${window.REACT_APP_API_URL}${STRATA_SEARCH_ORDERS}`;
    if (!startDate || !endDate) {
      notify('Please select both start and end dates', { type: 'warning' });
      return;
    }
    setSearchLoading(true);
    try {
      const userToken = localStorage.getItem('token');
      const response = await axios.post(strataSearchOrdersUrl, {
        flight_start_date: startDate.format('YYYY-MM-DD'),
        flight_end_date: endDate.format('YYYY-MM-DD'),
      }, {
        headers: {
          'Authorization': `Bearer ${userToken}`
        }
      });

      const ordersList = response.data.estimates;
      setOrdersList(ordersList);
      const estimateIdList = ordersList.map((order) => order.estimate_id);
      setEstimateIds(estimateIdList);
      setSearchLoading(false);
      refresh();
    } catch (error) {
      notify('Error fetching estimates', { type: 'error' });
      setSearchLoading(false);
    }
  };
  const handleSelectEstimate = (estimateId) => {
    const selectedEstimateOrder = ordersList.find((order) => order.estimate_id === estimateId);
    const selectedEstimateDestinations = selectedEstimateOrder.call_letters;
    const selectedUUIDs = selectedEstimateOrder.strata_letter_to_order_map;
    setEstimateData({
      selectedEstimateId: estimateId,
      selectedDestinations: selectedEstimateDestinations,
      selectedUUIDs: selectedUUIDs,
    });
  };

  const handleSelectContinue = () => {
    history.push({
      pathname: '/brs/create-strata',
      state: {
        estimateData, // Pass estimateData state
      },
    });
  };

  return (
    <SimpleForm toolbar={false}>
      <div className={classes.dateRangeContainer}>
        <DateRangePicker
          startDate={startDate}
          startDateId="start_date_id"
          endDate={endDate}
          endDateId="end_date_id"
          onDatesChange={({ startDate, endDate }) => {
            setStartDate(startDate);
            setEndDate(endDate);
          }}
          focusedInput={focusedInput}
          onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
          displayFormat="YYYY-MM-DD"
          isOutsideRange={() => false}
          appendToBody={true}
          openDirection="down"
          anchorDirection="left"
          withPortal={true}
          portalId="daterangepicker-portal"
          //showClearDates
        />
      </div>
      <Button label="Search" onClick={handleSearch} className={classes.searchButton} />
      {searchLoading && <CircularProgress color="inherit" className={classes.loadingSpinner} />}
      {estimateIds.length > 0 && (
        <SelectInput
          label="Estimate ID*"
          source="estimateId"
          choices={estimateIds.map((id) => ({ id, name: id }))}
          onChange={(e) => handleSelectEstimate(e.target.value)}
          className={classes.selectIdInput}
        />
      )}
      {estimateData.selectedEstimateId !== null && (
        <div className={classes.cancelContinueButton}>
          <Button label="Continue" onClick={handleSelectContinue} className={classes.actionButton} />
          <Button label="Cancel" onClick={() => setModalOpen(false)} className={classes.cancelButton} />
        </div>
      )}
    </SimpleForm>
  );
};

const BrsCreateStrataModalStyle = makeStyles((theme) => ({
  dateRangeContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '20px',
    '& .DateRangePicker': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    '& .DateRangePickerInput': {
      display: 'flex',
      flexDirection: 'row',
      border: 'unset', // to override the default existing border of react-dates library
      gap: '18px', // Adds space between Start Date and End Date inputs without affecting their individual styling
      backgroundColor: '#424242',
    },
    '& .DateInput': {
      width: '250px',
      borderBottom: '1px solid #ffffff',
      padding: '0 0 0 3px',
      backgroundColor: '#5A5A5A',
      height: '48px',
      display: 'flex',

      '&:hover': {
        backgroundColor: '#6B6B6B',
        borderColor: '#aaa',
      },
      '& .DateInput_input': {
        color: '#ffffff', // Set text color to white for both start and end dates
        '&:focus': {
          color: '#ffffff', // Keep the text color white when focused
        },
        '&:not(:placeholder-shown)': {
          color: '#ffffff', // Keep the color white even after selecting the date
        },
      },
    },
    '& .DateInput_input': {
      padding: '0',
      backgroundColor: '#5A5A5A',
      '&:hover': {
        backgroundColor: '#6B6B6B',
        borderColor: '#aaa',
      },
    },

    // Target the placeholder color in DateInput
    '& .DateInput_input::placeholder': {
      color: '#ffffff',
    },

    '& .DateRangePickerInput_arrow': {
      display: 'none !important',
    },
  },
  selectIdInput: {
    margin: '50px 0 20px 0',
    width: '360px',
  },
  button: {
    backgroundColor: '#3CE7EA',
    color: '#2A2A2A',
    borderRadius: 'unset',
    margin: '0 0 80px 0',
    padding: '4px 8px 4px 8px',
    textTransform: 'none',
    fontSize: '14px',
    opacity: 0.9,
    '&:hover': {
      backgroundColor: '#3CE7EA',
      color: '#2A2A2A',
      opacity: 1,
      boxShadow: 'none',
    },
    '&.Mui-disabled': {
      color: '#484646',
      backgroundColor: '#3c9191',
      pointerEvents: 'auto',
      cursor: 'not-allowed',
    },
  },
  cancelButton: {
    margin: '0 0 0 10px',
    backgroundColor: 'transparent',
    border: '1px solid #3CE7EA',
    color: '#3CE7EA',
    borderRadius: 'unset',
    padding: '4px 8px 4px 8px',
    opacity: 0.9,
    '&:disabled': {
      border: '1px solid #303030',
    },
    '&:hover': {
      backgroundColor: '#3CE7EA',
      color: '#2A2A2A',
      opacity: 1,
      boxShadow: 'none',
    },
  },
  searchButton: {
    width: '80px',
    backgroundColor: '#3CE7EA',
    color: '#2A2A2A',
    borderRadius: 'unset',
    padding: '4px 8px 4px 8px',
    textTransform: 'none',
    fontSize: '14px',
    opacity: 0.9,
    '&:hover': {
      backgroundColor: '#3CE7EA',
      color: '#2A2A2A',
      opacity: 1,
      boxShadow: 'none',
    },
    '&.Mui-disabled': {
      color: '#484646',
      backgroundColor: '#3c9191',
      pointerEvents: 'auto',
      cursor: 'not-allowed',
    },
  },
  loadingSpinner: {
    margin: '20px 0 0 0',
  },
  actionButton: {
    backgroundColor: '#3CE7EA',
    color: '#2A2A2A',
    borderRadius: 'unset',
    padding: '4px 8px 4px 8px',
    textTransform: 'none',
    fontSize: '14px',
    opacity: 0.9,
    '&:hover': {
      backgroundColor: '#3CE7EA',
      color: '#2A2A2A',
      opacity: 1,
      boxShadow: 'none',
    },
    '&.Mui-disabled': {
      color: '#484646',
      backgroundColor: '#3c9191',
      pointerEvents: 'auto',
      cursor: 'not-allowed',
    },
  },
  cancelContinueButton: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '10px',
  },
}));
