import React, { useState } from 'react';
import { useLogin, useNotify, TextInput, SimpleForm } from 'react-admin';
import { Button, CircularProgress } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import cadentLogo from '../../assets/cadentLogo.svg';
import { loginStyles } from '../../style';

export const Login = () => {
  const classes = loginStyles();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const login = useLogin();
  const notify = useNotify();

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      login({ username, password });
      // Login successful, React-admin will handle redirection
    } catch (error) {
      notify('Invalid username or password');
    }
    setLoading(false);
  };
  if (loading) {
    return (
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
  return (
    <div className={classes.loginPageWrapper}>
      <div>
        <img className={classes.cadentLogo} src={cadentLogo} alt="Cadent Logo" />
      </div>
      <SimpleForm onSubmit={handleLogin} toolbar={false}>
        <div className={classes.loginForm}>
          <TextInput
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            source="username"
            //fullWidth
            required
            className={classes.loginField}
          />

          <TextInput
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            source="password"
            //fullWidth
            required
            className={classes.loginField}
          />

          <Button type="submit" variant="contained" color="primary" disabled={loading} className={classes.loginButton}>
            {loading ? <CircularProgress size={24} /> : 'Login'}
          </Button>
        </div>
      </SimpleForm>
    </div>
  );
};
