import { Datagrid, List, TextField } from 'react-admin';
import { CustomPagination } from '../../components/CustomPagination';
import { FilterList, ListActions } from '../../components/index';

export const TranscoderList = (props) => {
  return (
    <List {...props} actions={<ListActions />} pagination={<CustomPagination />} filters={<FilterList />}>
      <Datagrid rowClick="edit">
        <TextField source="id" label="ID" />
        <TextField source="name" />
        <TextField source="transcoderconfig.host" label="Host" />
      </Datagrid>
    </List>
  );
};
