import { Datagrid, List, TextField } from 'react-admin';
import { CustomPagination } from '../../components/CustomPagination';
import { FilterList, ListActions } from '../../components/index';

export const FormatList = (props) => {
  return (
    <List
      {...props}
      perPage={100}
      actions={<ListActions />}
      pagination={<CustomPagination rowsPerPageOptions={[25, 50, 100, 250, 500]} />}
      filters={<FilterList />}>
      <Datagrid rowClick="edit">
        <TextField source="id" label="Format ID" />
        <TextField source="name" label="Format Name" />
        <TextField source="transcoder.name" label="Transcoder" />
        <TextField source="workflow_id" label="Workflow ID" />
      </Datagrid>
    </List>
  );
};
