import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput } from 'react-admin';
import { createUpdateStyle } from '../../style';
import Typography from '@material-ui/core/Typography';
import { CustomToolbar } from '../../components/CustomToolBar';

export const FormatCreate = (props) => {
  const classes = createUpdateStyle();
  return (
    <div className={classes.card}>
      <a className={classes.breadcrumbLink} href="#/format">
        {'< Formats'}
      </a>
      <Create title="Add Format" {...props}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar {...props} />}>
          <Typography variant="h6">Add Format</Typography>
          <ReferenceInput
            className={classes.textField}
            helperText={false}
            source="transcoder_id"
            reference="transcoder"
            sort={{ field: 'name', order: 'ASC' }}>
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput source="name" className={classes.textField} helperText={false} />
          <TextInput source="ext" label="EXT" className={classes.textField} helperText={false} />
          <TextInput source="output_dir" label="Ouput Folder" className={classes.textField} helperText={false} />
          <TextInput source="workflow_id" label="Workflow ID" className={classes.textField} helperText={false} />
          <TextInput
            style={{ display: 'none' }}
            source="discriminator"
            label="Discriminator"
            className={classes.textField}
            helperText={false}
          />
        </SimpleForm>
      </Create>
    </div>
  );
};
