import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#808080',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#66be72',
  },
  circle: {
    width: 14,
    height: 14,
    borderRadius: '50%',
    color: '#808080',
    backgroundColor: 'currentColor',
  },
  draft: {
    width: 14,
    height: 14,
    borderRadius: '50%',
    color: '#808080',
    backgroundColor: 'currentColor',
  },
  caution: {
    width: 14,
    height: 14,
    borderRadius: '50%',
    color: '#ff9800',
    backgroundColor: 'currentColor',
  },
  completed: {
    width: 14,
    height: 14,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
    color: '#66be72',
  },
  inProgress: {
    width: 14,
    height: 14,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
    color: '#fbb877',
  },
  fail: {
    width: 14,
    height: 14,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
    color: '#f7726d',
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPaper-root': {
      background: 'transparent',
    },
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['', '', '', ''];
}

const getStateClass = (icon, step_status) => {
  let status = {
    preflight_status: '',
    transcode_status: '',
    transfer_status: '',
    notification_status: '',
  };
  if (step_status.includes('PREFLIGHT')) {
    status.preflight_status = getActiveState(step_status);
  } else if (step_status.includes('TRANSCODES')) {
    status.preflight_status = 'completed';
    status.transcode_status = getActiveState(step_status);
  } else if (step_status.includes('TRANSFERS')) {
    status.preflight_status = 'completed';
    status.transcode_status = 'completed';
    status.transfer_status = getActiveState(step_status);
  } else if (step_status.includes('NOTIFICATIONS')) {
    status.preflight_status = 'completed';
    status.transcode_status = 'completed';
    status.transfer_status = 'completed';
    status.notification_status = getActiveState(step_status);
  }

  switch (icon) {
    case 1:
      return status.preflight_status;
    case 2:
      return status.transcode_status;
    case 3:
      return status.transfer_status;
    case 4:
      return status.notification_status;
    default:
      return;
  }
};

const getActiveState = (step_status) => {
  if (step_status.includes('IN_PROGRESS')) {
    return 'inProgress';
  }
  if (step_status.includes('CAUTION')) {
    return 'caution';
  }
  if (step_status.includes('COMPLETE')) {
    return 'completed';
  }
  if (step_status.includes('ERROR')) {
    return 'fail';
  }
};

const getActiveStep = (step_status) => {
  if (step_status.includes('PREFLIGHT')) {
    return 1;
  }
  if (step_status.includes('TRANSCODES')) {
    return 2;
  }
  if (step_status.includes('TRANSFERS')) {
    return 3;
  }
  if (step_status.includes('NOTIFICATIONS')) {
    return 4;
  }
};

const getBarActiveColor = (status) => {
  switch (getActiveState(status)) {
    case 'completed':
      return '#66be72';
    case 'inProgress':
      return '#fbb877';
    case 'fail':
      return '#f7726d';
    case 'caution':
      return '#ff9800';
    default:
      return '#808080';
  }
};

export const StepperField = (props) => {
  const classes = useStyles();
  const steps = getSteps();
  const record = props.record;
  const QontoStepIcon = (props) => {
    const classes = useQontoStepIconStyles();
    const { active, icon } = props;
    const className =
      record?.step_status && getStateClass(icon, record.step_status)
        ? getStateClass(icon, record.step_status)
        : 'circle';
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })}>
        <div className={classes[className]} />
      </div>
    );
  };

  const QontoConnector = withStyles({
    alternativeLabel: {
      top: 10,
      left: 'calc(-50% + 6px)',
      right: 'calc(50% + 6px)',
    },
    active: {
      '& $line': {
        borderColor: record?.step_status && getBarActiveColor(record.step_status),
      },
    },
    completed: {
      '& $line': {
        borderColor: '#66be72',
      },
    },
    inProgress: {
      '& $line': {
        borderColor: '#fbb877',
      },
    },
    caution: {
      '& $line': {
        borderColor: '#ff9800',
      },
    },
    line: {
      borderColor: '#808080',
      borderTopWidth: 3,
      borderRadius: 1,
    },
  })(StepConnector);

  return (
    <div className={classes.root}>
      <Stepper
        {...props}
        alternativeLabel
        activeStep={record?.step_status ? getActiveStep(record.step_status) : null}
        connector={<QontoConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel fail={true} StepIconComponent={QontoStepIcon}>
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};
