import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { Fragment, useEffect, useState } from 'react';
import { Datagrid, TextField, FunctionField, Loading, useQuery, Pagination } from 'react-admin';
import keyBy from 'lodash/keyBy';
import CircularProgress from '@material-ui/core/CircularProgress';
import WarningIcon from '@material-ui/icons/Warning';
import { Cancel as CancelIcon, CheckCircle as CheckCircleIcon } from '@material-ui/icons';

const useStyles = makeStyles({
  drawerPaper: {
    width: '20% !important',
  },
  tooltip: {
    fontSize: '14px',
  },
  rowCell: {
    borderBottom: '0px',
  },
  headerCell: {
    borderBottom: '0px',
  },
});

const AssetIsciList = (props) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [currentSort, setSort] = useState({ field: 'updated', order: 'ASC' });
  const { data, total, loading } = useQuery(
    {
      type: 'getList',
      resource: 'originalisci',
      payload: {
        pagination: { page, perPage },
        sort: currentSort,
        filter: {
          isciasset_id: props.record.isciasset.id,
        },
      },
    },
    {
      onFailure: (error) => {
        console.error(error);
      },
    },
  );

  const classes = useStyles();
  return data?.length > 0 ? (
    <Datagrid
      data={keyBy(data, 'id')}
      ids={data.map(({ id }) => id)}
      currentSort={currentSort}
      setSort={(field, order) => setSort({ field, order })}
      classes={{ rowCell: classes.rowCell, headerCell: classes.headerCell }}>
      <TextField source="isci" label="Original ISCI" />
      <TextField source="advertiser_name" label="Advertiser Name" />
      <TextField source="title" label="Title" />
      <TextField source="brand_name" label="Brand Name" />
      <TextField source="brand_code" label="Brand Code" />
    </Datagrid>
  ) : (
    <Loading></Loading>
  );
};

export const BroadcastRequestList = (props) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [currentSort, setSort] = useState({ field: 'created', order: 'ASC' });
  const [broadcast, setBroadcast] = useState(null);

  const { data, total, loading } = useQuery(
    {
      type: 'getList',
      resource: 'broadcastrequest',
      payload: {
        pagination: { page, perPage },
        sort: currentSort,
        filter: {
          brs_job_id: props.brs_job.id,
        },
      },
    },
    {
      onFailure: (error) => {
        console.error(error);
      },
    },
  );

  useEffect(() => {
    setBroadcast(data);
  }, [data]);

  if (loading) {
    return <Loading />;
  }
  return props.brs_job && broadcast ? (
    <Fragment>
      <Datagrid
        data={keyBy(broadcast, 'id')}
        ids={broadcast.map(({ id }) => id)}
        currentSort={currentSort}
        setSort={(field, order) => setSort({ field, order })}
        hasBulkActions={false}
        expand={<AssetIsciList />}>
        <TextField source="isciasset.isci" label="Copy ID" />
        <TextField source="isciasset.duration" label="Duration" />
        <TextField source="isciasset.sigma_encoding" label="Sigma Encoding" />
        <FunctionField
          label="Preflight"
          render={(r) => {
            if (!r.step_history) {
              return '';
            } else if (r.step_history['PREFLIGHT'] === 'IN_PROGRESS') {
              return <CircularProgress size={30} />;
            } else if (r.step_history['PREFLIGHT'] === 'CAUTION') {
              return <WarningIcon />;
            } else if (r.step_history['PREFLIGHT'] === 'COMPLETE') {
              return <CheckCircleIcon />;
            } else if (r.step_history['PREFLIGHT'] === 'ERROR') {
              return <CancelIcon />;
            }
          }}
        />
        <FunctionField
          label="Delivery"
          render={(r) => {
            if (!r.step_history) {
              return '';
            } else if (r.step_history['TRANSFERS'] === 'IN_PROGRESS') {
              return <CircularProgress size={30} />;
            } else if (r.step_history['TRANSFERS'] === 'CAUTION') {
              return <WarningIcon />;
            } else if (r.step_history['TRANSFERS'] === 'COMPLETE') {
              return <CheckCircleIcon />;
            } else if (r.step_history['TRANSFERS'] === 'ERROR') {
              return <CancelIcon />;
            }
          }}
        />
      </Datagrid>
      <Pagination
        page={page}
        perPage={perPage}
        setPage={setPage}
        setPerPage={setPerPage}
        total={total}
        rowsPerPageOptions={[25, 50, 100, 250, 500]}
      />
    </Fragment>
  ) : (
    <Loading />
  );
};
