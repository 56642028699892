import * as React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
  logo: {
    textAlign: 'center',
    fontWeight: 900,
    color: '#F6F9FA',
    textTransform: 'uppercase',
    fontSize: '24px',
  },
});

const MyAppBar = (props) => {
  const classes = useStyles();
  return (
    <AppBar {...props}>
      <Typography variant="h6" color="inherit" className={classes.title} id="react-admin-title" />
      <Typography className={classes.logo}>CADENT</Typography>
      <span className={classes.spacer} />
    </AppBar>
  );
};

export default MyAppBar;
