import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
  },
}))(LinearProgress);

export const ProgressField = (props) => {
  const { type } = props;
  let status = props.record?.status;
  if (type === 'format') {
    status = props.record?.transfergroup?.status;
  }
  let progresBarColor;
  let value;
  if (type === 'destination') {
    value = props.record.progress;
    if (status === 'Verified') {
      progresBarColor = '#67B861';
    } else if (status === 'Delivered') {
      progresBarColor = '#3698cf';
    } else if (status === 'Failed') {
      progresBarColor = '#EB5F5F';
    } else if (status === 'Bounced' || status === 'Opened') {
      progresBarColor = '#ff9800';
    } else {
      progresBarColor = '#F3AC64';
    }
  } else if (status) {
    if (status === 'IN_PROGRESS') {
      progresBarColor = '#F3AC64';
      if (type === 'transcoder') {
        value = props.record.transcode_percent;
      } else if (type === 'format') {
        value = props.record.transfergroup.transfer_percent;
      } else if (type === 'job') {
        value = 50;
      }
    } else if (status === 'COMPLETE') {
      progresBarColor = '#67B861';
      value = 100;
    } else if (status === 'ERROR') {
      progresBarColor = '#EB5F5F';
      value = 100;
    } else if (status === 'CAUTION') {
      progresBarColor = '#ff9800';
      value = 100;
    }
  }
  const useStyles = makeStyles({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: '#6C6C6C',
    },
    bar: {
      borderRadius: 5,
      backgroundColor: progresBarColor,
    },
  });

  const classes = useStyles();

  return (
    <BorderLinearProgress
      variant="determinate"
      classes={{
        root: classes.root,
        colorPrimary: classes.colorPrimary,
        bar: classes.bar,
      }}
      value={value || 0}
    />
  );
};
